import { Step } from '@components/stepper/step.abstract';
import { Injectable } from '@angular/core';
import { CartService } from '@services/cart.service';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';

@Injectable({
  providedIn: 'root',
})
export class CustomerStepService extends Step {
  public code = 'customer';
  public path = '/panier/titulaire';
  public label = 'Titulaire';

  protected hasMedi7Product = false;
  protected hasSubmitted = false;

  constructor(private cartService: CartService) {
    super();
  }

  get isAllowed(): boolean {
    if (this.isSubmitted) {
      const medi7InCart = !!this.cartService.getCurrentScheme()?.getProductByType<InsurancePartner>(InsurancePartner);
      if (!this.hasSubmitted) {
        this.hasSubmitted = medi7InCart;
      }
      this.hasSubmitted = true;
      if (medi7InCart !== this.hasMedi7Product) {
        this.isSubmitted = false;
        this.hasSubmitted = false;
      }
    }
    const currentScheme = this.cartService.getCurrentScheme(false);
    return !!currentScheme && currentScheme.products.length > 0;
  }
  set isAllowed(val: boolean) {
    return;
  }
}
