import { Component, OnInit } from '@angular/core';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { ICartHasReservedProdResponse } from '@interfaces/types';
import { User } from '../../../user/user';
import { UserService } from '@services/user.service';
import { DatePipe } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-modal-product-unreservation',
  templateUrl: './modal-product-unreservation.component.html',
  styleUrls: ['./modal-product-unreservation.component.scss'],
  standalone: true,
  imports: [DatePipe],
})
export class ModalProductUnreservationComponent implements OnInit {
  public productUnreservationDto: ICartHasReservedProdResponse;
  public cancelButtonText = 'Non';
  public validButtonText = 'Oui';
  public user: User;

  constructor(
    private ref: DialogRef,
    private globalLoaderService: GlobalLoaderService,
    private userService: UserService,
  ) {}

  public ngOnInit(): void {
    this.productUnreservationDto = this.ref.data.productUnreservationDto;
    this.user = this.userService.user;
  }

  public onCloseClick(): void {
    this.globalLoaderService.setForceLoadingStatusOff(false);
    this.ref.close();
  }

  public onValidClick(): void {
    this.globalLoaderService.setForceLoadingStatusOff(false);
    this.ref.close('OK');
  }

  public dismiss(): void {
    this.ref.close();
  }
}
