import { Component, effect, model, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { ProductsService } from '@services/products.service';
import { CartService } from '@services/cart.service';
import {
  forkJoin,
  from as observableFrom,
  Observable,
  of as observableOf,
  of,
  Subscription,
  from,
  throwError,
} from 'rxjs';
import { ScanForm, StockType } from '@interfaces/types';
import { Catalog } from '@model/catalog/products/catalog';
import { ViewService } from '@services/view.service';
import { JsonCatalog, JsonProduct } from '@model/catalog/products/interface/context';
import { Complex } from '@model/catalog/products/equipement/complex';
import { Accessory } from '@model/catalog/products/equipement/accessory';
import { PricingProductsInterface } from '@components/pricing-products/pricing-products.interface';
import { PricingAccessoryService } from '@components/pricing-accessory/pricing-accessory.service';
import { Product } from '@model/catalog/products/product';
import { ScanditService } from '@services/scandit.service';
import { InputForScan, ScanditFieldInterface } from '@interfaces/scandit.interface';
import { ComparatorService } from '@services/comparator.service';
import { catchError, concatMap, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { FormsModule } from '@angular/forms';
import { ScanDirective } from '../scan.directive';
import { ViewComponent } from '@components/category/view/view.component';
import { DialogService } from '@ngneat/dialog';
import { ModalStockChoiceComponent } from '@components/modal/modal-stock-choice/modal-stock-choice.component';
import { SchemeService } from '@services/scheme.service';
import { AcquisitionScheme } from '@model/acquisition-scheme';
import { Scheme } from '@model/scheme.class';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import * as _ from 'lodash';
enum TypesStock {
  bytel = '0001',
  ca = 'CA01',
}

@Component({
  selector: 'rcbt-form-scan',
  templateUrl: './form-scan.component.html',
  styleUrls: ['./form-scan.component.scss'],
  standalone: true,
  imports: [FormsModule, ScanDirective],
})
export class FormScanComponent implements OnInit, OnDestroy {
  public scanCode = model<string>();
  public previousCode = signal<string | undefined>(undefined);

  public products: JsonCatalog;
  public productsCa: JsonCatalog;
  public stockData: StockType[];
  public message = '';
  public scanLoading = false;
  public scanListener: Subscription;
  protected codeInput = '';
  private consultingAccessoryPrices = false;
  private lastScanCodeInput = '';
  private lastScanCodeInputTimed = '';
  private lastScanCodeTimeout: NodeJS.Timeout;
  private subscriptions = new Subscription();

  /**
   * [constructor description]
   * @return {[type]}                                 [description]
   * @param productsService
   * @param cartService
   * @param view
   * @param pricingAccessoryService
   * @param scanditService
   * @param comparatorService
   * @param browseConfigService
   * @param dialogService
   * @param schemeService
   * @param globalLoaderService
   * @param checkoutStepperService
   */
  constructor(
    private productsService: ProductsService,
    private cartService: CartService,
    private view: ViewService,
    protected pricingAccessoryService: PricingAccessoryService,
    private scanditService: ScanditService,
    private comparatorService: ComparatorService,
    private browseConfigService: BrowseConfigService,
    private dialogService: DialogService,
    private schemeService: SchemeService,
    protected globalLoaderService: GlobalLoaderService,
    protected checkoutStepperService: CheckoutStepperService,
  ) {
    effect(() => {
      const currentCode = this.scanCode();
      if (currentCode && currentCode !== this.previousCode() && !this.view.sourceScan && currentCode?.length) {
        this.codeInput = this.scanCode();
        this.onSubmit({ scanCode: this.scanCode() });
        this.previousCode.set(currentCode);
      }
    });
  }

  /**
   * [ngOnInit description]
   * @return {[type]} [description]
   */
  public ngOnInit(): void {
    this.consultingAccessoryPrices = this.browseConfigService.browseConfig.browseActionType === 'IHMPRIXACCESSOIRE';
    if (this.scanditService.isOnTablet() || this.browseConfigService.browseConfig.debug) {
      this.scanditService.openScanForField(InputForScan.scanTopBar, true);
      this.scanListener = this.scanditService.scanForField.subscribe((data: ScanditFieldInterface) => {
        if (data.field === InputForScan.scanTopBar) {
          this.onSubmit({ scanCode: data.scanCode });
        }
      });
    }
  }

  public ngOnDestroy(): void {
    if (this.scanListener) {
      this.scanListener.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }

  get _scanCode(): string {
    return this.scanCode();
  }

  public scanChange(event: string): void {
    let _scanCode = event;
    // Gestion des codes barre UPC-A via douchette :
    //    Les codes barres UPC-A (12 digits) sont une sous-partie des EAN (13 digits) mais commençant par un '0'
    //    Les douchettes gèrent les UPC-A en n'envoyant que les 12 digits, et en ignorant le '0' de début.
    //    De plus les douchettes envoie les digits un par un, mais très vite.
    //    Palliatif :
    //      - lastScanCodeInput sert à stocker la dernière valeur saisie dans l'input
    //      - lastScanCodeTimeout sert à gérer le timeout de mise à jour de la valeur tant que l'on modifie dans les 300ms, on ne met pas à jour
    //          Ainsi, une saisie manuelle va mettre à jour la variable lastScanCodeInput à chaque caractère, mais un copier/coller ou la saisie rapide de la douchette ne la mettra pas à jour.
    //      - s'il n'y avait pas de valeur, et que l'on passe directement à 12 digit, alors on rajoute le '0' implicite en début du scanCode
    clearTimeout(this.lastScanCodeTimeout);
    const copyPaste = !this.lastScanCodeInput && _scanCode?.length === 12;
    this.lastScanCodeTimeout = setTimeout(() => {
      if (!this.lastScanCodeInputTimed && _scanCode?.length === 12) {
        // this.scanCode.set('0' + this.scanCode());
        _scanCode = '0' + _scanCode;
        if (!copyPaste) {
          // this.onSubmit({ scanCode: this.scanCode() });
          this.scanCode.set(_scanCode);
        }
      }
      this.lastScanCodeInputTimed = this.scanCode();
    }, 300);
    this.lastScanCodeInput = this.scanCode();
    this.message = '';
  }

  public onSubmit(value: ScanForm): void {
    this.lastScanCodeInput = value.scanCode;
    this.view.sourceScan = true;
    this.onSubmitCode(value)
      .pipe(
        finalize(() => {
          this.view.sourceScan = false;
        }),
      )
      .subscribe();
  }

  public scan(): void {
    const products: JsonProduct[] = [];
    // has two stocks club associé et bytel
    this.stockData.forEach(p => {
      if (p.quantity) {
        if (p.typeStock === TypesStock.bytel && this.products && this.products?.[p.gencode]) {
          products.push(this.products[p.gencode]);
        } else if (p.typeStock === TypesStock.ca && this.productsCa && this.productsCa?.[p.gencode]) {
          this.productsCa[p.gencode].type_id = 'produit_ca';
          products.push(this.productsCa[p.gencode]);
        }
      }
    });
    const duplicatedProducts = products
      .filter((product, index, self) => index !== self.findIndex(p => p.gencode === product.gencode))
      .map(p => p.gencode);
    let addProductObs: Observable<unknown>[] = [];
    if (duplicatedProducts.length) {
      const dialogRef = this.dialogService.open(ModalStockChoiceComponent, {
        minWidth: '80%',
        maxWidth: '80%',
        closeButton: false,
        data: {
          sourceProducts: duplicatedProducts.map(gencode => [this.products[gencode], this.productsCa[gencode]]).flat(),
          currentScheme: this.cartService.getCurrentScheme(),
        },
      });
      dialogRef.afterClosed$.subscribe(data => {
        if (data) {
          addProductObs = data.map(p => observableFrom(p));
          addProductObs = [
            ...addProductObs,
            ...products
              .filter(p => !duplicatedProducts.includes(p.gencode))
              .map(p => observableOf(Catalog.getInstance(p))),
          ];
          this._addProducts(addProductObs);
        }
      });
    } else {
      addProductObs = products.map(p => observableOf(Catalog.getInstance(p)));
    }
    this._addProducts(addProductObs);
    this.lastScanCodeInput = '';
    this.codeInput = '';
    this.scanCode.set('');
  }

  /**
   * [qview description]
   * @return {[type]} [description]
   */
  public qview(parentCode: string, childCode: string, scanCode: string, childTypeId: string): void {
    if (!this.products[childCode]) {
      this.message = 'Produit désactivé';
      return;
    }
    const _scanCode = scanCode?.length ? scanCode : this.previousCode();

    this.dialogService.open(ViewComponent, {
      data: {
        parentCode: parentCode,
        equipmentType: childTypeId,
        childrenCodes: [childCode],
        bestChild: childCode,
        scanCode: _scanCode,
      },
    });
  }

  public loadScan(): void {
    if (this.browseConfigService.browseConfig.debug || this.scanditService.isOnTablet()) {
      this.scanditService.openScanForField(InputForScan.scanTopBar);
    }
  }

  protected _getScanCode(scanCode: string): string {
    while (scanCode.length < 13) {
      scanCode = '0' + scanCode;
    }
    return scanCode;
  }

  protected _addProductSalesOnly(product: Product): Observable<void> {
    return this.schemeService.createScheme(new AcquisitionScheme(), this.cartService.cart).pipe(
      mergeMap(() => this.cartService.refreshCart()),
      tap(() =>
        this.cartService
          .add(product)
          .pipe(mergeMap(() => this.cartService.refreshCart()))
          .subscribe(() =>
            this.checkoutStepperService.goToStep(
              this.checkoutStepperService.getStepByCode(product.type_id === 'phone_simple' ? 'cart' : 'customer'),
            ),
          ),
      ),
    );
  }

  private onSubmitCode(value: ScanForm): Observable<void> {
    this.comparatorService.reset();
    this.scanLoading = true;
    this.message = '';
    if (this.consultingAccessoryPrices) {
      this.pricingAccessoryService.sendMessage([]);
    }
    this.scanCode.set(this._getScanCode(value.scanCode));

    // is multiple scan => get Array of scanned codes
    const scannedCodes = this.scanCode()?.trim().split(',') ?? [];
    const products = this.cartService
      .getAllProducts()
      .filter(tmpProduct => tmpProduct instanceof Complex && scannedCodes.includes(tmpProduct.getScanCode()));
    if (products.length) {
      this.stockData = products.map(p => ({ gencode: p.gencode, quantity: 1, typeStock: TypesStock.bytel }));
      this.scanLoading = false;
      return this.loadProducts().pipe(map(() => this.scan()));
    }

    const obs = scannedCodes.map(code =>
      (this.consultingAccessoryPrices
        ? this.cartService.getItemFromStockForStandalone(code)
        : this.cartService.getItemFromStockOrReservedProducts(code)
      ).pipe(catchError(error => of(error && error.message ? error.message : error))),
    );
    return forkJoin(obs).pipe(
      mergeMap((items: StockType[][]) => {
        const errorMsgs = items.filter(i => typeof i === 'string');
        if (errorMsgs.length) {
          this.message = "Impossible d'ajouter un ou plusieurs accessoires";
        }
        this.stockData = items.flat();
        return this.loadProducts();
      }),
      mergeMap(() => {
        this.scanLoading = false;
        this.scan();
        return of(void 0);
      }),
      catchError(error => {
        this.message = error && error.message ? error.message : error;
        this.scanLoading = false;
        return of(null);
      }),
    );
  }

  private hasCategory(product: Product, category: string): boolean {
    return product.data.categories.some(cat => cat === category);
  }

  private loadProducts(): Observable<void> {
    const loadProductsObs: Observable<JsonCatalog>[] = [];
    this.stockData.forEach(data => {
      if (data.quantity) {
        loadProductsObs.push(this.loadProduct(data.gencode, data.typeStock as TypesStock));
      }
    });
    return loadProductsObs.length ? forkJoin(loadProductsObs) : of(null);
  }

  private loadProduct(gencode: string, stockType: TypesStock): Observable<JsonCatalog> {
    const loadProductObs: Observable<JsonCatalog> =
      stockType === TypesStock.bytel
        ? this.productsService.getJsonProductsByFilter({ listGencode: gencode })
        : this.productsService.getJsonCaProductsByFilter({ listGencode: gencode });
    return loadProductObs.pipe(
      tap(result => {
        if (stockType === TypesStock.bytel) {
          this.products = _.cloneDeep({ ...(this.products || {}), ...result });
        } else {
          this.productsCa = _.cloneDeep({ ...(this.productsCa || {}), ...result });
        }
      }),
    );
  }

  private _addProducts(addProductObs: Observable<unknown>[]): void {
    const _scannedCodes = (this.scanCode()?.length ? this.scanCode() : this.lastScanCodeInput).split(',');
    const addAccessoryObs: Observable<unknown>[] = [];
    forkJoin(addProductObs).subscribe((productInstances: Product[]) => {
      productInstances.forEach(productInstance => {
        if (this.consultingAccessoryPrices) {
          if (!(productInstance instanceof Accessory)) {
            this.message = 'Le code barre ne correspond pas à un accessoire.';
            return;
          } else {
            const inputsComponent: PricingProductsInterface = {
              name: productInstance.name,
              thumbnail: productInstance.data.thumbnail,
              price: productInstance.price,
              oldPrice: productInstance.price,
              quantity: this.stockData[0].quantity,
              gencode: productInstance.gencode,
            };
            this.pricingAccessoryService.sendMessage([inputsComponent]);
          }
          return;
        }

        if (
          productInstance.type_id &&
          productInstance.data.priorite_sav &&
          productInstance.type_id === 'box' &&
          productInstance.data.priorite_sav !== ''
        ) {
          this.message = 'Non vendable sur ce parcours';
          return;
        }

        if (!_scannedCodes.some(code => productInstance.setScanCode(code))) {
          this.message = 'Code barre invalide';
          return;
        }

        if (!this.cartService.currentSchemeUniqueId) {
          this.subscriptions.add(
            this.globalLoaderService
              .showLoaderUntilFinalize(this._addProductSalesOnly(productInstance), 'globalLoading')
              .subscribe(),
          );
          this.lastScanCodeInput = '';
          this.codeInput = '';
          this.scanCode.set('');
          return;
        }

        if (
          this.cartService.getCurrentScheme().isAcquisitionFix() &&
          ['produit_ca', 'accessory'].indexOf(productInstance.type_id) === -1
        ) {
          this.message = "Type d'element scanné non autorisé sur ce type parcours!";
          return;
        }

        switch (productInstance.type_id) {
          case 'accessory':
          case 'produit_ca':
            addAccessoryObs.push(this.cartService.add(productInstance));
            break;
          case 'phone_simple':
          case 'box':
            if (!productInstance.data.parent_code && productInstance.type_id === 'phone_simple') {
              this.message = 'Produit mal configuré';
              return;
            }
            const parentCode: string = productInstance.data.parent_code
              ? productInstance.data.parent_code
              : this.stockData[0].gencode;
            if (
              !this.cartService.isProductAvailable(productInstance) ||
              (this.cartService.getCurrentScheme().isAcquisitionMobile() &&
                this.hasCategory(productInstance, 'rcbt_fai'))
            ) {
              this.message = 'Vente non autorisée';
              return;
            }
            this.qview(parentCode, this.stockData[0].gencode, this.scanCode(), productInstance.type_id);
            break;
          default:
            break;
        }
      });
      // ajout des produits en parallele not supported par l'api
      from(addAccessoryObs)
        .pipe(
          concatMap(request => request),
          mergeMap(() => this.cartService.refreshCart()),
        )
        .subscribe({
          next: () => {
            this.message = this.stockData.some(data => typeof data === 'string') ? this.message : '';
            this.products = {};
            this.productsCa = {};
            this.scanCode.set('');
          },
          error: () => (this.message = "Impossible d'ajouter un ou plusieurs accessoires"),
        });
    });
  }
}
