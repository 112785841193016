<div class=" replace-sim-page" [ngClass]="{'margin': showHeader}">
    <div class="card">
        <div class="card-content">
            <h2 class="title is-3">Remplacement de SIM</h2>
            <hr class="row-display">

            <rcbt-sim [isReplaceSimComponent]="true"></rcbt-sim>
        </div>
    </div>
</div>
