<rcbt-top-bar
  [isSummaryPage]="onSummaryPage"
  [isConsultAccessoryPricePage]="onAccessoryPricePage"
  [isEligStandalonePage]="onEligPage"
  [isCustomWelcomePage]="onCustomWelcomePage"
  [onCustomRecapPage]="onCustomRecapPage"
  (isFormScanOpen)="scanFormIsOpen = $event"
></rcbt-top-bar>
<!--m-t-54 when scan fofrm is opened-->
<div [ngClass]="{ 'm-t-24': !scanFormIsOpen, 'm-t-54': scanFormIsOpen }">
  <router-outlet></router-outlet>
</div>
