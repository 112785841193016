export enum OpenBankingEventStatus {
  openBankingEligible = 'openBankingEligible',
  openBankingDone = 'openBankingDone',
  openBankingCanceled = 'openBankingCanceled',
  openBankingReset = 'openBankingReset',
}

export interface OpenBankingEventData {
  status: OpenBankingEventStatus;
  iban?: string;
}

export interface OpenBankingEligibilityResult {
  concernedQuoteId?: string;
  isEligible: boolean;
}

export const OB_LOADING_ACTIONS = {
  checkOpenBankingEligibility: '[OpenBankingComponent] checkOpenBankingEligibility',
  checkOpenBankingProcess: '[OpenBankingComponent] checkOpenBankingProcess',
  createOpenBankingProcess: '[OpenBankingComponent] createOpenBankingProcess',
  cancelOpenBankingProcess: '[OpenBankingComponent] cancelOpenBankingProcess',
};

export const OB_STATUS_MSGS = {
  CREE: "Le parcours d'Open Banking n'a pas été commencé. Récupération de l'IBAN impossible",
  EN_COURS: "Le parcours d'Open Banking est en cours. Récupération de l'IBAN impossible",
  ABANDONNE: "Le parcours d'Open Banking a été abandonné. Récupération de l'IBAN impossible",
  ERREUR: "Une erreur est survenue. Récupération de l'IBAN impossible",
  EXPIRE: "La session d'Open Banking a expiré. Veuillez recommencer le parcours d'Open Banking",
  REFUSE: "Le parcours d'Open Banking a été refusé par le client. Récupération de l'IBAN impossible",
  DEFAULT: "Une erreur est survenue. Récupération de l'IBAN impossible",
};

export enum OB_ACTIONS {
  CHANGE_FUNDING = 'CHANGE_FUNDING',
  CLOSE_FUNDING = 'CLOSE_FUNDING',
}
