import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { AlertService } from '@services/alert.service';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-modal-consent',
  templateUrl: './modal-consent.component.html',
  styleUrls: ['./modal-consent.component.scss'],
  imports: [FormsModule],
})
export class ModalConsentComponent implements OnInit, OnDestroy {
  public message: string;
  public consentAgreed: boolean;
  public loading = false;
  public loadingSubscription: Subscription;

  constructor(
    private readonly configService: ConfigService,
    private readonly alertService: AlertService,
    protected ref: DialogRef,
  ) {
    this.loadingSubscription = this.alertService.isLoading.subscribe((load: boolean) => (this.loading = load));
  }

  public ngOnInit(): void {
    this.consentAgreed = false;
    this.message = this.configService.data.textPopupCredit;
  }

  public ngOnDestroy(): void {
    this.loadingSubscription?.unsubscribe();
  }

  public closeModal(validated?: boolean): void {
    this.ref.close(validated);
  }
}
