<div class="p-x-4">
  @if (error) {
    <div class="is-flex is-fullwidth is-justified-center m-t-10">
      <button class="button is-info" (click)="reload()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
        <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
      </button>
    </div>
  } @else {
    <div class="columns">
      <rcbt-side-bar-filter (filtersChangedEmitter)="onFiltersChanged()"></rcbt-side-bar-filter>
      <div class="is-flex is-vertical is-fullwidth">
        <rcbt-comparator-parameters></rcbt-comparator-parameters>
        @if (allPlans) {
          <rcbt-top-bar-filter [allPlans]="allPlans" (filtersChangedEmitter)="onFiltersChanged()" [nbCurrentPhones]="(equipments$ | async).length" [nbAllPhones]="nbAllPhones">
          </rcbt-top-bar-filter>
        }
        <div class="product-list p-b-4 p-x-4">
          <rcbt-infinite-scroll (scrolled)="onScroll()" [hasFetchedAllEquipments]="hasFetchedAllEquipments" [scrollInProgress]="scrollInProgress">
            <div class="is-flex is-multiline g-1" rcbtQview>
              @for (equipment of equipments$ | async; track trackByFn(i, equipment); let i = $index) {
                <div
                  class="is-flex one-device is-vertical"
                  [id]="equipment.gencode"
                  [ngClass]="{ isReco: equipment.recommande, selectedForCompare: isParentSelected(equipment) }"
                  (click)="productClick(equipment)"
                >
                  @if (equipment.recommande) {
                    <div class="isRecoTitle">Sélectionné pour votre client</div>
                  }
                  <rcbt-product-item [index]="i" [equipment]="equipment"> </rcbt-product-item>
                </div>
              }
            </div>
          </rcbt-infinite-scroll>
          @if (scrollInProgress) {
            <div class="is-flex is-fullwidth is-justified-center is-aligned-center">
              <div class="notification has-body has-background-warning-20 is-warning m-y-5">
                <div class="icon">
                  <i class="tri-exclamation-circle" aria-hidden="true"></i>
                </div>
                <div class="body">
                  <div class="text is-size-3">Chargement en cours ...</div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }
</div>
