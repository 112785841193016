import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Person } from '../identity.interface';
import { CustomerCategory } from '../../customer.interface';
import { PrismeLoggerService } from '../../../../../shared/prisme/prisme-logger.service';
import { PrismeLogType } from '../../../../../shared/prisme/prisme-log-type.enum';
import { CustomerService } from '../../customer.service';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'list-pu',
  templateUrl: './list-pu.component.html',
  styleUrls: ['./list-pu.component.scss'],
  standalone: true,
  imports: [],
})
export class ListPuComponent implements OnInit, OnDestroy {
  public persons: Person[] = [];
  public selectedPu: Person;
  private subscription = new Subscription();
  public enableAttach = false;
  public isAllPro = false;

  constructor(
    private prismeService: PrismeLoggerService,
    private customerService: CustomerService,
    protected ref: DialogRef,
  ) {}

  public ngOnInit(): void {
    this.persons = this.ref.data.persons;
    this.isAllPro = this.persons.every(p => p.type === CustomerCategory.pro);

    this.prismeService.sendDataToPrisme(PrismeLogType.customLog, {
      message: 'Ouverture de la popin des listes des PUs',
    });

    if (this.persons?.length === 1) {
      this.selectedPu = this.persons[0];
      this.enableAttach =
        this.persons[0].nbActiveLines === 0 &&
        (!this.persons[0].hasExistingContracts || this.persons[0].areAllContractsTerminated) &&
        !this.customerService.customer.isPro() &&
        this.persons[0].type === CustomerCategory.gp;
    }
  }

  public cancel(): void {
    this.prismeService.sendDataToPrisme(PrismeLogType.customLog, { message: 'Bouton Quitter sans rattacher cliqué' });
    this.ref.close(false);
  }

  public attach(): void {
    this.prismeService.sendDataToPrisme(PrismeLogType.customLog, { message: 'Bouton Rattacher cliqué' });
    this.ref.close(this.selectedPu);
  }

  public selectPu(pu: Person): void {
    this.enableAttach =
      pu.nbActiveLines === 0 &&
      (!pu.hasExistingContracts || pu.areAllContractsTerminated) &&
      !this.customerService.customer.isPro() &&
      pu.type === CustomerCategory.gp;
    this.selectedPu = pu;
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
