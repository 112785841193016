import { Component, OnInit } from '@angular/core';
import { EDuplicateAction } from '@fai-widget/fai.interfaces';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-duplicate-command',
  templateUrl: './duplicate-command.component.html',
  standalone: true,
  styleUrls: ['./duplicate-command.component.scss'],
})
export class DuplicateCommandComponent implements OnInit {
  public idCommand: string;

  constructor(protected ref: DialogRef) {}
  public ngOnInit(): void {
    this.idCommand = this.ref.data?.idCommand;
  }

  public cancel(): void {
    this.ref.close(EDuplicateAction.cancel);
  }

  public change(): void {
    this.ref.close(EDuplicateAction.reset);
  }
}
