@if (alertService.messages.length > 0) {
  <ul class="alert-container alert is-success">
    @for (message of alertService.messages; track message) {
      <li  data-cy="alert-success" role="alert">"{{message}}"</li>
    }
  </ul>
}

@if (alertService.errors.length > 0) {
  <ul class="alert-container alert is-danger">
    @for (error of alertService.errors; track error) {
      <li  data-cy="alert-error" role="alert">"{{error}}"</li>
    }
  </ul>
}
