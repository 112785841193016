import { Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(protected ref: DialogRef) {}

  public decline(): void {
    this.ref.close(false);
  }

  public accept(): void {
    this.ref.close(true);
  }
}
