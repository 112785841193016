<article class="accordion has-background-white" [ngClass]="{ 'is-active': isActive }">
  <div class="accordion-header" (click)="onClickTab(customerService.customerSteps.address)">
    <div class="title is-size-1" data-cy="title-address">Coordonnées de facturation</div>
    <button class="toggle" aria-label="toggle"></button>
  </div>
  <div class="divider" [hidden]="!isActive"></div>
  <div class="accordion-body">
    <div class="accordion-content">
      @if (!!msgAddress) {
        <div class="has-text-danger">{{ msgAddress }}</div>
      }
      @if (!!message) {
        <div class="has-text-danger">{{ message }}</div>
      }
      <form
        class="form-data"
        [formGroup]="addressForm"
        novalidate
        (ngSubmit)="onGenericAddressSubmit()"
        data-cy="address-form"
        [ngClass]="{ 'is-address-form-disabled': addressForm.disabled }"
      >
        <div class="is-flex m-b-2" id="locationField" [hidden]="!isAuto">
          <div class="field is-fullwidth">
            <div class="control has-icons-right has-dynamic-placeholder">
              @if (googleIsLoaded) {
                <input
                  data-cy="auto-address"
                  #address="ngx-places"
                  autocomplete="off"
                  type="text"
                  id="auto-address"
                  class="input"
                  formControlName="address"
                  (focus)="resetAddress()"
                  [(ngModel)]="addressModel"
                  [options]="options"
                  (onAddressChange)="getAddress($event)"
                  ngx-gp-autocomplete
                  [ngClass]="{ 'is-success': addressForm.controls.address.valid && !!addressForm.value.address, 'is-danger': addressForm.controls.address.errors }"
                />
              }
              <label for="auto-address" rcbtAsterisk [formcontrol]="addressForm.controls.address">Adresse</label>
              <span class="icon">
                @if (addressForm.controls.address.valid && addressForm.value.address) {
                  <i class="tri-check-circle has-background-success-60"></i>
                }
                @if (addressForm.controls.address.errors) {
                  <i class="tri-exclamation-circle has-background-error-60"></i>
                }
              </span>
            </div>
          </div>
        </div>

        <div class="is-flex m-b-2" [hidden]="isAuto">
          <div class="field first m-b-0">
            <div class="control has-icons-right has-dynamic-placeholder">
              <input
                id="input-numeroRue"
                data-cy="input-numeroRue"
                autocomplete="off"
                type="text"
                class="input"
                formControlName="numeroRue"
                placeholder="N°"
                [maxLength]="15"
                [ngClass]="{ 'is-success': addressForm.controls.numeroRue.valid && !!addressForm.value.numeroRue, 'is-danger': addressForm.controls.numeroRue.errors }"
                #numeroRue
              />
              <label for="input-numeroRue" rcbtAsterisk [formcontrol]="addressForm.controls.numeroRue">N°</label>
              <span class="icon">
                @if (addressForm.controls.numeroRue?.valid && !!addressForm.value.numeroRue) {
                  <i class="tri-check-circle has-background-success-60"></i>
                }
                @if (addressForm.controls.numeroRue.errors) {
                  <i class="tri-exclamation-circle has-background-error-60"></i>
                }
              </span>
            </div>
            <small class="has-text-danger" [hidden]="!addressForm.controls.numeroRue.errors || addressForm.controls.numeroRue.valid || needCheck">
              Veuillez saisir un numéro !
            </small>
          </div>
          <div class="field is-fullwidth m-l-4 m-t-0">
            <div class="control has-icons-right has-dynamic-placeholder">
              <input
                id="input-rue"
                data-cy="input-rue"
                autocomplete="off"
                type="text"
                class="input"
                formControlName="rue"
                placeholder="Rue, Avenue, ..."
                [maxLength]="60"
                [ngClass]="{ 'is-success': addressForm.controls.rue.valid && addressForm.value.rue, 'is-danger': addressForm.controls.rue.errors }"
                #rue
              />
              <label for="input-rue" rcbtAsterisk [formcontrol]="addressForm.controls.rue">Rue, Avenue, ...</label>
              <span class="icon">
                @if (addressForm.controls.rue.valid && addressForm.value.rue) {
                  <i class="tri-check-circle has-background-success-60"></i>
                }
                @if (addressForm.controls.rue.errors) {
                  <i class="tri-exclamation-circle has-background-error-60"></i>
                }
              </span>
            </div>
            <small class="has-text-danger" [hidden]="!addressForm.controls.rue.errors || addressForm.controls.rue.valid || needCheck">
              Veuillez saisir un nom de voie, rue, ... !
            </small>
          </div>
        </div>

        <div class="is-flex m-b-2">
          <div class="field is-fullwidth">
            <div class="control has-icons-right has-dynamic-placeholder">
              <input
                id="input-complementAdresse"
                data-cy="input-complementAdresse"
                autocomplete="off"
                type="text"
                class="input"
                formControlName="complementAdresse"
                placeholder="Complément..."
                [maxLength]="40"
                [ngClass]="{
                  'is-success': addressForm.controls.complementAdresse.valid && addressForm.value.complementAdresse,
                  'is-danger': addressForm.controls.complementAdresse.errors,
                }"
                #complementAdresse
              />
              <label for="input-complementAdresse" rcbtAsterisk [formcontrol]="addressForm.controls.complementAdresse">Complément...</label>
              <span class="icon">
                @if (addressForm.controls.complementAdresse.valid && addressForm.value.complementAdresse) {
                  <i class="tri-check-circle has-background-success-60"></i>
                }
                @if (addressForm.controls.complementAdresse.errors) {
                  <i class="tri-exclamation-circle has-background-error-60"></i>
                }
              </span>
            </div>
          </div>
        </div>

        <div class="is-flex m-b-2" [hidden]="isAuto">
          <div class="field first m-b-0">
            <div class="control has-icons-right has-dynamic-placeholder">
              <input
                id="input-codePostal"
                data-cy="input-codePostal"
                autocomplete="off"
                type="text"
                class="input"
                formControlName="codePostal"
                placeholder="Code postal"
                [ngClass]="{ 'is-success': addressForm.controls.codePostal.valid && addressForm.value.codePostal, 'is-danger': addressForm.controls.codePostal.errors }"
                #codePostal
              />
              <label for="input-codePostal" rcbtAsterisk [formcontrol]="addressForm.controls.codePostal">Code postal</label>
              <span class="icon">
                @if (addressForm.controls.codePostal.valid && addressForm.value.codePostal) {
                  <i class="tri-check-circle has-background-success-60"></i>
                }
                @if (addressForm.controls.codePostal.errors) {
                  <i class="tri-exclamation-circle has-background-error-60"></i>
                }
              </span>
            </div>
            <small class="has-text-danger" [hidden]="!addressForm.controls.codePostal.errors || addressForm.controls.codePostal.valid || needCheck">
              Veuillez saisir un code postal !
            </small>
          </div>
          <div class="field is-fullwidth m-l-4 m-t-0">
            <div class="control has-icons-right has-dynamic-placeholder">
              <input
                id="input-ville"
                data-cy="input-ville"
                autocomplete="off"
                type="text"
                class="input"
                formControlName="ville"
                placeholder="Ville"
                [ngClass]="{ 'is-success': addressForm.controls.ville.valid && addressForm.value.ville.length, 'is-danger': addressForm.controls.ville.errors }"
                #ville
              />
              <label for="input-ville" rcbtAsterisk [formcontrol]="addressForm.controls.ville">Ville</label>
              <span class="icon">
                @if (addressForm.controls.ville.valid && addressForm.value.ville) {
                  <i class="tri-check-circle has-background-success-60"></i>
                }
                @if (addressForm.controls.ville.errors) {
                  <i class="tri-exclamation-circle has-background-error-60"></i>
                }
              </span>
            </div>
            <small class="has-text-danger" [hidden]="!addressForm.controls.ville.errors || addressForm.controls.ville.valid || needCheck"> Veuillez saisir une ville ! </small>
          </div>
        </div>

        @if (!addressForm.disabled) {
          <div class="m-b-2">
            <div class="field one">
              <a class="link-help text is-level-1 has-text-weight-semibold has-text-info" [hidden]="!isAuto" (click)="isAuto = false">Je ne trouve pas l'adresse</a>
              <a class="link-help text is-level-1 has-text-weight-semibold has-text-info" [hidden]="isAuto" (click)="isAuto = true">Saisie d'adresse en mode assisté</a>
            </div>
          </div>
        }
      </form>
      @if (schemeHasSubscription) {
        <form [formGroup]="documentForm" novalidate (ngSubmit)="onBillingAddressSubmit()"></form>
      }
      @if (errorMessage.length > 0) {
        <div class="error-message">
          <hr />
          <small class="has-text-danger">{{ errorMessage }}</small>
        </div>
      }
    </div>
  </div>
</article>
