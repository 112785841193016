import { Component, OnInit } from '@angular/core';
import { CartService } from '@services/cart.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { BrowseType } from '../../../constants/browse-type';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { Complex } from '@model/catalog/products/equipement/complex';
import { Plan } from '@model/catalog/products/subscription/plan';

@Component({
  selector: 'rcbt-category-nav-bar',
  templateUrl: 'category-nav-bar.component.html',
  standalone: true,
  styleUrls: ['./category-nav-bar.component.scss'],
  imports: [RouterLink, RouterLinkActive],
})
export class CategoryNavBarComponent implements OnInit {
  public telephoneRoute: string[];
  public isRenew = false;
  public category: string;
  public hasSim: boolean;
  public canShowReplaceSim = false;

  constructor(
    private cartService: CartService,
    private customerService: CustomerService,
  ) {}

  public ngOnInit(): void {
    this.isRenew = this.cartService.getCurrentScheme().browseType === BrowseType.renew;
    this.telephoneRoute = this.isRenew ? ['/category/', 'telephones', 'renew'] : ['/category/', 'telephones'];
    this.hasSim =
      this.customerService.customer.ligneMarche === 'FNB' ||
      (['1', '2'].indexOf(this.customerService.customer.ligneMarche) !== -1 &&
        this.customerService.customer.telephonePrepaye.length > 0);
    this.canShowReplaceSim = this.hasSim && !this.isRenew && !this.hasEquipmentOrPlan();
  }

  private hasEquipmentOrPlan(): boolean {
    const scheme = this.cartService.getCurrentScheme();
    return !!scheme.getProductByType(Complex) || !!scheme.getProductByType(Plan);
  }
}
