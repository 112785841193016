<!-- TOP-BAR-FILTER -->
@if (componentSource === componentSourceList.topBarFilter) {
  <div class="buttonTakeBack">
    <button [disabled]="disableButton" class="button is-secondary" data-cy="reprise-mobile-phone" (click)="takebackService.openModal()">Reprise Mobile</button>
    @if (takebackService.takebackPriceEstimation !== undefined) {
      <div class="takebackEstimation has-text-secondary">
        <div class="is-flex is-aligned-center">
          @if (takebackService.takebackPriceEstimation === 0) {
            <span>Recycler le mobile</span>
          }
          @if (takebackService.takebackPriceEstimation > 0) {
            <span>
              Rachat mobile jusqu'à : <strong>{{ takebackService.takebackPriceEstimation }} € (capacité minimum).</strong>
            </span>
          }
          @if (takebackService.takebackNameMobile) {
            <span class="icon is-small has-text-secondary" aria-label="Icone de info" (click)="displayMobileName = !displayMobileName">
              <i class="tri-infos-circle" aria-hidden="true"></i>
            </span>
          }
        </div>
        @if (takebackService.takebackNameMobile && displayMobileName) {
          <span class="takebackMobileName has-text-secondary">
            Pour le téléphone : <strong>{{ takebackService.takebackNameMobile }}</strong>
          </span>
        }
      </div>
    }
  </div>
}

<!-- MOBILE-TAKE-BACK -->
@if (componentSource === componentSourceList.mobileTakeBack) {
  <div class="is-flex is-aligned-center">
    <div class="is-flex is-vertical">
      @if (takebackService.takebackPriceEstimation !== undefined) {
        <div class="has-text-secondary is-flex is-aligned-center">
          @if (takebackService.takebackPriceEstimation === 0) {
            <div class="is-flex is-aligned-center is-size-5">Recycler le mobile</div>
          }
          @if (takebackService.takebackPriceEstimation > 0) {
            <div class="is-flex is-aligned-center is-size-5">
              <span class="icon is-medium has-text-secondary" aria-label="Icone de Tel"><i class="tri-mobile-euro" aria-hidden="true"></i></span>
              &nbsp;jusqu'à :&nbsp;<strong>{{ takebackService.takebackPriceEstimation }} €</strong>
            </div>
          }
          @if (takebackService.takebackNameMobile) {
            <span class="icon is-medium has-text-secondary" aria-label="Icone de info" (click)="displayMobileName = !displayMobileName">
              <i class="tri-infos-circle" aria-hidden="true"></i>
            </span>
          }
        </div>
      }
      @if (takebackService.takebackNameMobile && displayMobileName) {
        <div class="takebackMobileName has-text-secondary">
          Pour le téléphone : <strong>{{ takebackService.takebackNameMobile }}</strong>
        </div>
      }
    </div>
    <button data-cy="reprise-mobile-phone" class="button is-info" data-cy="reprise-mobile-phone" (click)="takebackService.openModal()">
      {{ takebackService.takebackPriceEstimation !== undefined ? 'Estimer' : 'Reprise mobile' }}
    </button>
  </div>
}
