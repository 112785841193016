import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
  public transform(value: unknown, args?: unknown): unknown {
    if (!value && value !== 0) {
      return '';
    }
    const price: string[] = parseFloat(value.toString()).toFixed(2).split('.');
    if (price[1].length === 1) {
      price[1] += '0';
    }
    return `
        <span class="main text">${price[0]}</span>
        <span class="price-details">
          <span class="cents">€${price[1]}</span>
        </span>
    `;
  }
}
