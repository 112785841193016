import { Component } from '@angular/core';
import { PlansComponent } from '../plans.component';
import { IPostCatalogResponse } from '../../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { NgClass, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PriceComponent } from '@base/price/price.component';
import { CustomCurrencyPipe } from '@base/pipes/currency.pipe';

@Component({
  selector: 'rcbt-catalog-category-view-acquise',
  templateUrl: '../plans.component.html',
  styleUrls: ['../plans.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, PriceComponent, AsyncPipe, CustomCurrencyPipe],
})
export class PlansAcquiseComponent extends PlansComponent {
  public filterPlans(): void {
    super.filterPlans();
    this.filterPlansForAcquise();
  }

  protected filterPlansForAcquise(): void {
    const catPlanChoosed = this.plansType.find(catPlan => catPlan.type === this.planType);
    if (catPlanChoosed) {
      this.produitsFiltres = this.produitsFiltres.filter(
        (p: IPostCatalogResponse) =>
          !p.excluPro && p.categories.includes(catPlanChoosed.category) && !p.estNonVendableAcquisition,
      );
    }
  }
}
