<div class="is-flex is-spaced-between p-b-1 p-x-4" [ngClass]="{ 'm-b-10': takebackService.takebackPriceEstimation !== undefined }">
  <div class="is-flex is-aligned-bottom is-size-6 has-text-weight-semibold m-r-2">{{ nbCurrentPhones }}/{{ nbAllPhones }}&nbsp;téléphones</div>
  <div class="is-flex is-vcentered ">
    <div class="field is-flex is-aligned-center m-0">
      <div class="control has-dynamic-placeholder">
        <select id="select-offer" class="select has-text-tertiary select-banc p-x-3" [(ngModel)]="filterService.phoneFiltersAndSort.planSelected" (change)="emitFiltersAndSort()">
          @for (plan of plansToDisplay; track plan) {
            <option [value]="plan.gencode">{{ plan.name }}</option>
          }
        </select>
        <label for="select-offer">Offre</label>
      </div>
    </div>

    @if (isMtbStandAloneActive && !isRenew) {
      <div class="m-x-2">
        <rcbt-takeback-button [componentSource]="componentSourceTakeback" [disableButton]="hasMobileTakeBack"></rcbt-takeback-button>
      </div>
    }

    @if (plansForComparator) {
      <rcbt-comparator-toggle [plansForComparator]="plansForComparator"></rcbt-comparator-toggle>
    }

    <div class="field is-flex is-aligned-center m-0">
      <div class="control has-dynamic-placeholder">
        <select id="sort-select" class="select has-text-tertiary p-x-3" [(ngModel)]="filterService.phoneFiltersAndSort.sortSelected" (change)="emitFiltersAndSort()">
          @for (option of filterService.triOptions; track option) {
            <option [value]="option.key">{{ option.label }}</option>
          }
        </select>
        <label for="sort-select">Trié par</label>
      </div>
    </div>
  </div>
</div>
