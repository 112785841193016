<div class=" modal-list-pu">
  <div class="pu-header">
    <div class="title is-size-3 is-centered m-x-50 m-t-20 pu-title-height">
      Êtes-vous sûr qu'il s'agit bien de la personne suivante?
      Attention, il ne sera pas possible de modifier ensuite.
    </div>
  </div>
  <div class="body rows m-0">
    @for (pu of persons; track pu; let i = $index) {
      <div class="radio-tile is-horizontal pu-width is-centered row">
        <input type="radio" id="list-pu-{{i}}" [checked]="persons.length === 1" name="select-pu" (click)="selectPu(pu)">
        <label for="list-pu-{{i}}" class="radio-label">
          <ul class="list is-tertiary text is-size-5">
            @if (pu.type) {
              <li>Type de client : {{pu.type}} </li>
            }
            @if (pu.civility) {
              <li>Civilité : {{pu.civility}}</li>
            }
            @if (pu.name) {
              <li>Nom : {{pu.name}}</li>
            }
            @if (pu.firstname) {
              <li>Prénom : {{pu.firstname}}</li>
            }
            @if (pu.birthDate) {
              <li>Date de naissance : {{pu.birthDate}} </li>
            }
            @if (pu.departmentOfBirth) {
              <li>Département de naissance : {{pu.departmentOfBirth}} </li>
            }
            @if (pu.address.addressLinear) {
              <li>Adresse : {{pu.address.addressLinear}}</li>
            }
            @if (pu.email) {
              <li>Mail de contact : {{pu.email}}</li>
            }
            @if (pu.nbActiveLines > 0 || (!pu.hasExistingContracts || pu.areAllContractsTerminated)) {
              <li>
                Client avec {{pu.nbActiveLines}} ligne(s) active(s)
              </li>
            }
          </ul>
        </label>
      </div>
    }
  </div>
  <div class="actions">
    <button class="button is-primary" (click)="cancel()">Quitter sans rattacher</button>
    <button class="button is-info" [disabled]="!enableAttach" (click)="attach()">Rattacher</button>
  </div>
</div>
