@if (!loadServicesError) {
  <div class="services-page" [ngClass]="{'margin': showHeader}">
    @for (service of servicesList; track service) {
      <div id="gencode_{{service.code}}" class="card" [ngClass]="{'added': service.state === serviceStates.added}">
        <div class="card-content">
          <div class="service-name">
            <label>{{service.name}}</label>
          </div>
          <div class="service-price">
            <rcbt-price [price]="service.price" [fontLevel]="5"></rcbt-price>
          </div>
          <div>
            @if (service.state === serviceStates.available) {
              <button class="button is-info p-2" [attr.data-cy]="'srv_add_'+service.code"
                (click)="addService(service.code)">
                <span class="icon is-small m-0" aria-label="Ajout au panier">
                  <i class="tri-shopping-cart has-background-white" aria-hidden="true"></i>
                </span>
              </button>
            }
            @if (service.state === serviceStates.added) {
              <button class="button is-warning p-2" [attr.data-cy]="'srv_remove_'+service.code"
                (click)="removeService(service.code)">
                <span class="icon is-small m-0" aria-label="Suppression du panier">
                  <i class="tri-trash has-background-white" aria-hidden="true"></i>
                </span>
              </button>
            }
            @if (service.state === serviceStates.incompatible) {
              <button class="button is-info p-2" [attr.data-cy]="'srv_add_'+service.code" [disabled]="true">
                <span class="icon is-small m-0" aria-label="Ajout au panier">
                  <i class="tri-shopping-cart has-background-white" aria-hidden="true"></i>
                </span>
              </button>
            }
          </div>
        </div>
      </div>
    }
  </div>
}
@if (loadServicesError) {
  <div class=" row reload-button is-justified-center p-20">
    <button class="button is-info" (click)="loadServices()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
      <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
    </button>
  </div>
}

