import { Component, EventEmitter, OnInit } from '@angular/core';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { RechercherVerificationBancaireOut } from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import {
  OB_ACTIONS,
  OB_LOADING_ACTIONS,
  OB_STATUS_MSGS,
  OpenBankingEventData,
  OpenBankingEventStatus,
} from '@checkout/cart/customer/payment/open-banking/open-banking.interfaces';
import { OpenBankingService } from '@checkout/cart/customer/payment/open-banking/open-banking.service';
import { DialogRef } from '@ngneat/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-open-banking-modal',
  templateUrl: './open-banking-modal.component.html',
  styleUrls: ['./open-banking-modal.component.scss'],
  standalone: true,
  imports: [],
})
export class OpenBankingModalComponent implements OnInit {
  public hideWordingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public disableOB: () => void;
  public emitOpenBankingRealized: (iban: string) => void;
  public parseOpenBankingErrorStatus: (result: RechercherVerificationBancaireOut) => void;
  public isRecoveryUser = false;
  public errorMsg = '';

  private _subscription = new Subscription();
  constructor(
    protected ref: DialogRef,
    private globalLoaderService: GlobalLoaderService,
    private openBankingService: OpenBankingService,
  ) {}

  public ngOnInit(): void {
    this.isRecoveryUser = this.ref.data?.isRecoveryUser;
  }

  public getIban(): void {
    this.errorMsg = null;
    this._subscription.add(
      this.globalLoaderService
        .showLoaderUntilFinalize(
          this.openBankingService.checkOpenBankingProcess({ noPersonne: this.ref.data.noPersonne }),
          OB_LOADING_ACTIONS.checkOpenBankingProcess,
        )
        .subscribe({
          next: (result: RechercherVerificationBancaireOut) => {
            switch (result.statut) {
              case 'CREE':
              case 'EN_COURS':
                this.errorMsg = this._getStatutMessage(result.statut);
                break;
              case 'REALISE':
                this.hideWordingEvent.emit(true);
                this.closeModal({
                  iban: result.informationsSupplementaires.iban,
                  done: true,
                });
                break;
              case 'REFUSE':
                this.disableOB();
                this.closeModal({
                  hasRefusedOpenBanking: true,
                });
                this.hideWordingEvent.emit(true);
                break;
              case 'ERREUR':
                this.disableOB();
                this.errorMsg = this._getStatutMessage(result.statut);
                this.closeModal({ isOpenBankingEligible: false });
                break;
              default:
                this.disableOB();
                this.parseOpenBankingErrorStatus(result);
                this.closeModal();
                break;
            }
          },
          error: () => {
            this.closeModal({ isOpenBankingEligible: false, status: OpenBankingEventStatus.openBankingCanceled });
          },
        }),
    );
  }

  public changeFunding(): void {
    this.closeModal({ action: OB_ACTIONS.CHANGE_FUNDING });
  }

  public closeFunding(): void {
    this.closeModal({ action: OB_ACTIONS.CLOSE_FUNDING });
  }

  public closeModal(data?: Record<string, unknown>): void {
    this.ref.close(data || {});
  }

  private _getStatutMessage(statut: string): string {
    return OB_STATUS_MSGS[statut] || OB_STATUS_MSGS.DEFAULT;
  }
}
