<div class="price-component">
  @if ((!!product && !!product.oldPrice && product.oldPrice !== product.price) || (!product && !!oldPrice && oldPrice !== price)) {
    <div class="price is-striked" itemprop="oldprice" [class]="'is-level-'+fontLevelStrike"
      >
      <span class="price-main">{{ (product ? product.oldPrice : oldPrice) | price:'units' }}</span>
      <span class="price-details">
        <span class="cents"><span itemprop="priceCurrency" content="EUR">€</span>{{ (product ? product.oldPrice : oldPrice) | price:'cents' }}</span>
        @if ((product ? product.priceType : priceType) == 1) {
          <span class="period">/mois</span>
        }
      </span>
    </div>
  }
  <div class="price" itemprop="price" [ngClass]="'is-level-'+fontLevel" [class]="(mainPrice ? 'is-main-price' : '')">
    <span class="price-main" [ngClass]="{'has-text-secondary': isSecondaryNegatif && (product ? product.price : price) < 0}">{{ (product ? product.price : price) | price:'units' }}</span>
    <span class="price-details">
      <span class="cents" [ngClass]="{'has-text-secondary': isSecondaryNegatif && (product ? product.price : price) < 0}"><span itemprop="priceCurrency" content="EUR">€</span>{{ (product ? product.price : price) | price:'cents' }}</span>
      @if ((product ? product.priceType : priceType) == 1) {
        <span class="period" [ngClass]="{'has-text-secondary': isSecondaryNegatif && (product ? product.price : price) < 0}">/mois</span>
      }
    </span>
  </div>
</div>
