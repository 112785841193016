import { Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-modal-medi7-warning',
  templateUrl: './modal-medi7-warning.component.html',
  styleUrls: ['./modal-medi7-warning.component.scss'],
  standalone: true,
})
export class ModalMedi7WarningComponent {
  constructor(protected ref: DialogRef) {}
  public continue(): void {
    this.ref.close();
  }
}
