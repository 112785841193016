import { Component, Injectable, OnInit } from '@angular/core';
import { LogService } from '@base/services/log.service';
import { AlertService } from '@services/alert.service';
import { FrontLog } from '@base/interceptor/Interceptor.interface';
import { CartService } from '@services/cart.service';
import { JsonPipe } from '@angular/common';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-log-front',
  templateUrl: './log.component.html',
  standalone: true,
  styleUrls: ['./log.component.scss'],
  imports: [JsonPipe],
})
@Injectable()
export class LogComponent implements OnInit {
  public controlErrors: Object[] = [];

  constructor(
    protected logService: LogService,
    protected alertService: AlertService,
    public cartService: CartService,
    protected ref: DialogRef,
  ) {}

  public ngOnInit(): void {
    this.initLogMessages();
  }

  /**
   * close
   */
  public close(): void {
    this.ref.close();
  }

  /**
   * copy
   */
  public copy(): void {
    const selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    let val;
    if (this.controlErrors) {
      val = JSON.stringify(this.controlErrors);
      this.alertService.successEmitter.next('Copié dans le presse-papier!');
    } else {
      this.alertService.errorEmitter.next('rien à copier');
      return;
    }
    selBox.value = val;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  protected initLogMessages(): void {
    this.reset();
    const frontStorageLogs = this.logService.getFrontStorageLogs();
    if (frontStorageLogs) {
      frontStorageLogs.forEach((frontStorageLog: FrontLog) => {
        this.controlErrors.push({
          time: frontStorageLog.time,
          request: frontStorageLog.request,
          response: frontStorageLog.error,
        });
      });
    }
  }

  private reset(): void {
    this.controlErrors = [];
  }
}
