import { EventEmitter, Injectable } from '@angular/core';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { ComptesFacturation, Person, PuType, SearchPerson, SearchPersonsResult, Status } from './identity.interface';
import { Observable } from 'rxjs';
import { ListPuComponent } from './list-pu/list-pu.component';
import { CustomerCategory } from '../customer.interface';
import { DialogService } from '@ngneat/dialog';

@Injectable({ providedIn: 'root' })
export class IdentityService {
  public attachPerson$: EventEmitter<Person> = new EventEmitter<Person>();

  constructor(
    private oauth2RessourceService: Oauth2RessourceService,
    private dialogService: DialogService,
  ) {}

  public openAssociationPopin(persons: SearchPerson[]): void {
    const options = {
      data: {
        persons: this.formatSearchPersonResult(persons),
      },
      minWidth: '80%',
      maxWidth: '80%',
      closeButton: false,
    };
    const dialogRef = this.dialogService.open(ListPuComponent, options);
    dialogRef.afterClosed$.subscribe(result => {
      if (result) {
        this.attachPerson$.emit(result);
      }
    });
  }

  private formatSearchPersonResult(searchPersonResult: SearchPerson[]): Person[] {
    return searchPersonResult.map((p: SearchPerson) => {
      const address = p.comptesFacturation ? p.comptesFacturation[0].adresseDeclaree : undefined;
      return {
        idPu: p.idPersonneUnique,
        email: p.email,
        hasExistingContracts: p.comptesFacturation && p.comptesFacturation.length > 0,
        address: {
          addressLinear: address
            ? `${address.numero || ''} ${address.rue} ${address.codePostal} ${address.ville} ${address.codePays}`
            : '',
          streetNumber: address?.numero || '',
          street: address?.rue,
          postalcode: address?.codePostal,
          city: address?.ville,
          country: address?.codePays,
        },

        nbActiveLines: this.getNbActiveLines(p),
        areAllContractsTerminated:
          p.comptesFacturation && p.comptesFacturation[0]?.contratsPayes.every(cp => cp.statut === Status.Terminated),
        paymentAccount: {
          iban: p.comptesFacturation && p.comptesFacturation[0]?.compteBancaire?.iban,
          bic: p.comptesFacturation && p.comptesFacturation[0]?.compteBancaire?.bic,
        },
        ...this.getPersonIdentity(p),
      };
    });
  }

  private getPersonIdentity(p: SearchPerson): {
    type: string;
    civility: string;
    firstname: string;
    name: string;
    birthDate: string;
    departmentOfBirth: string;
  } {
    return {
      type: p._type === PuType.Individu ? CustomerCategory.gp : CustomerCategory.pro,
      civility: p._type === PuType.Individu ? p.civilite : p.representantLegal?.civilite,
      firstname: p._type === PuType.Individu ? p.prenom : p.representantLegal?.prenom,
      name: p._type === PuType.Individu ? p.nom : p.representantLegal?.nom,
      birthDate: (p._type === PuType.Individu ? p.dateNaissance : p.representantLegal?.dateNaissance)
        ?.split('-')
        .reverse()
        .join('/'),
      departmentOfBirth:
        p._type === PuType.Individu ? p.departementNaissance : p.representantLegal?.departementNaissance,
    };
  }

  private getNbActiveLines(p: SearchPerson): number {
    return (
      p.comptesFacturation?.reduce<number>((acc, cf: ComptesFacturation) => {
        const nbActiveLine = cf.contratsPayes?.filter(cp => cp.statut === Status.Actif).length || 0;
        acc = acc + nbActiveLine;
        return acc;
      }, 0) || 0
    );
  }

  public getSearchPersonData(email: string, queryParamName: string): Observable<SearchPersonsResult> {
    const encodedEmail = encodeURIComponent(email);
    return this.oauth2RessourceService.rechercherPersonne(encodedEmail, queryParamName).get();
  }
}
