import { Component, Input, OnInit } from '@angular/core';
import { TakebackService } from '@services/takeback.service';
import { EComponentSource } from '@interfaces/takeback.interface';

@Component({
  selector: 'rcbt-takeback-button',
  templateUrl: './takeback-button.component.html',
  styleUrls: ['./takeback-button.component.scss'],
  standalone: true,
})
export class TakebackButtonComponent implements OnInit {
  @Input() componentSource: EComponentSource;
  @Input() disableButton = false;
  public componentSourceList = EComponentSource;
  public displayMobileName = false;

  constructor(public takebackService: TakebackService) {}

  public ngOnInit(): void {
    this.takebackService.setTakebackPriceEstimation();
  }
}
