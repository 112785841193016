import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CartService } from '@services/cart.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { ConfigService } from '@services/config.service';

// Note: dependencies of legacy plan list
import { JsonCatalog, JsonProduct } from '@model/catalog/products/interface/context';
import { PromoRenewService } from '@services/promo-renew.service';
import { RenewScheme } from '@model/renew-scheme';
import { Sensation } from '@model/catalog/products/subscription/plan/premium/sensation';
import { Sowo } from '@model/catalog/products/subscription/plan/sowo';
import { ProductSerialized } from '@model/catalog/products/interface/configurable';
import { FilterService } from '@services/filter.service';
import { EComponentSource } from '@interfaces/takeback.interface';
import { TakebackService } from '@services/takeback.service';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TakebackButtonComponent } from '@components/takeback/takeback-button.component';
import { ComparatorToggleComponent } from '@components/comparator/comparator-toggle.component';

@Component({
  selector: 'rcbt-top-bar-filter',
  templateUrl: './top-bar-filter.component.html',
  standalone: true,
  imports: [NgClass, FormsModule, TakebackButtonComponent, ComparatorToggleComponent],
})
export class TopBarFilterComponent {
  @Output() filtersChangedEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() allPlans: JsonCatalog;
  @Input() nbCurrentPhones: number;
  @Input() nbAllPhones: number;

  public isRenew = this.cartService.getCurrentScheme().isRenew();

  private plansForFilter: JsonProduct[];
  public plansForComparator: JsonProduct[];
  public plansToDisplay: { gencode: string; name: string }[];

  public hasMobileTakeBack = !!this.cartService.getCurrentScheme().products.find(p => p.type_id === 'mobile-take-back');
  public isMtbStandAloneActive = false;
  public componentSourceTakeback = EComponentSource.topBarFilter;

  constructor(
    private customerService: CustomerService,
    private configService: ConfigService,
    private cartService: CartService,
    // Note: dependencies of legacy plan list
    private promoRenewService: PromoRenewService,
    public filterService: FilterService,
    public takebackService: TakebackService,
  ) {
    this.isMtbStandAloneActive = this.configService.data.mtbStandAlone?.active;
  }

  public ngAfterViewInit(): void {
    this.cartService.onAddProduct.subscribe((product: ProductSerialized) => {
      if (product.type_id === 'mobile-take-back') {
        this.hasMobileTakeBack = true;
      }
    });

    this.getPlanList();
  }

  public emitFiltersAndSort(): void {
    this.filtersChangedEmitter.next();
  }

  private getPlanList(): void {
    this.plansForFilter = [];
    if (this.customerService.customer.isPro()) {
      this.plansForFilter = Object.values(this.allPlans).filter((plan: JsonProduct) => plan.exclu_pro);
      this.plansForFilter.sort((a, b) => b.final_price - a.final_price);
    }
    this.plansForFilter = this.plansForFilter.concat(
      Object.values(this.allPlans)
        .filter((plan: JsonProduct) => plan.exclu_pro !== true)
        .sort((a, b) => b.final_price - a.final_price),
    );
    if (this.isRenew) {
      this.filterPlansForRenew();
    } else {
      this.plansForComparator = [...this.plansForFilter];
    }
    this.setPlansToDisplay();
    this.selectDefaultPlan();
    this.filtersChangedEmitter.emit();
  }

  private filterPlansForRenew(): void {
    const renewScheme = this.cartService.getCurrentScheme() as RenewScheme;
    const currentPlan = this.allPlans[this.promoRenewService.renewPromotion.opSource.idOffre];
    if (
      renewScheme.planType === Sensation.planType &&
      currentPlan?.obligation === 'monthly24' &&
      renewScheme.phoneSeniority < 22
    ) {
      this.plansForFilter = this.plansForFilter.filter(plan => plan.obligation === 'monthly24');
    }
    this.plansForComparator = [...this.plansForFilter];
    this.excludeNotPremiumPlans();
  }

  private excludeNotPremiumPlans(): void {
    this.plansForFilter = this.plansForFilter.filter(plan => !plan.type_id.startsWith(Sowo.planType));
  }

  private setPlansToDisplay(): void {
    this.plansToDisplay = this.plansForFilter.map(p => ({ gencode: p.gencode, name: p.name }));
    if (!this.isRenew) {
      this.plansToDisplay.push({ gencode: '', name: 'Terminal seul' });
    }
  }

  private selectDefaultPlan(): void {
    let defaultPlan: string = null;

    if (this.isRenew) {
      const currentOffer = this.allPlans[this.promoRenewService.renewPromotion.opSource.idOffre];
      if (!currentOffer.type_id.startsWith(Sowo.planType)) {
        defaultPlan = this.promoRenewService.renewPromotion.opSource.idOffre;
      }
    } else if (this.configService.data.defaultWallPlan) {
      if (
        this.configService.data.defaultWallPlan.GP &&
        this.plansForFilter.some((plan: JsonProduct) => plan.gencode === this.configService.data.defaultWallPlan.GP)
      ) {
        defaultPlan = this.configService.data.defaultWallPlan.GP;
      }
    }
    this.filterService.defaultPlan = defaultPlan || this.plansForFilter[0].gencode;
    this.filterService.phoneFiltersAndSort.planSelected = this.filterService.defaultPlan;
  }
}
