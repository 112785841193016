<div class="sim-format">
  @if (isRenew && !isOldSimCompatible) {
    <div class="is-5 has-text-warning icon-message">
      <span class="icon is-small"><i class="tri-comment-exclamation-circle" aria-hidden="true"></i></span>
      <p>
        <strong> Attention, la sim en cours d'utilisation est incompatible avec le téléphone sélectionné. </strong>
      </p>
    </div>
  }

  @if (isQrEsimCompatible || isSimCompatible) {
    <div class="options is-centered">
      <div class="field">
        <div class="control">
          <input
            type="radio"
            id="radio-sim"
            name="simType"
            (click)="changeSimChoice(typeSim.simPhysique)"
            [disabled]="!isSimCompatible"
            [checked]="choosenSimType === typeSim.simPhysique || !isQrEsimCompatible"
          />
          <label for="radio-sim" data-cy="choice-sim">SIM</label>
        </div>
      </div>
      @if (isQrEsimCompatible && (tpvActive || !isPaperMode)) {
        <div class="field">
          <div class="control">
            <input type="radio" id="radio-esim" name="simType" (click)="changeSimChoice(typeSim.qrEsim)" [checked]="choosenSimType === typeSim.qrEsim" />
            <label for="radio-esim" data-cy="choice-esim">eSIM</label>
          </div>
        </div>
      }
    </div>
  }

  @if (choosenSimType === typeSim.simPhysique) {
    <form class="form-sim is-centered" #simForm="ngForm" (ngSubmit)="scanSim()">
      <div [hidden]="!!simCode">
        <div class="control has-icons-right">
          <input
            data-cy="sim-plan"
            [(ngModel)]="scanCode"
            autocomplete="off"
            id="example-input"
            name="scanCode"
            maxlength="13"
            minlength="13"
            placeholder="Numéro de SIM"
            class="input"
            type="text"
            pattern="[0-9]{13,13}$"
            required
          />
          @if (scanCode) {
            <span class="icon" (click)="removeScanCode()"> <i class="tri-times-circle"></i></span>
          }
        </div>
      </div>
      @if (simCode) {
        <div class="ccid-confirmed">
          <span class="icon is-medium is-success"><i class="tri-check" aria-hidden="true"></i></span>
          <span class="ccid-value">{{ simCode }}</span>
          <button type="button" class="button is-warning p-2" data-cy="sim-remove" (click)="removeSim()" [ngClass]="{ 'action-feedback': loading }">
            <span class="icon is-small m-0"><i class="tri-trash has-background-white" aria-hidden="true"></i></span>
          </button>
        </div>
      }

      @if (!(simForm.valid || !onTablete || !!simCode)) {
        <button class="button is-info p-2" (click)="scanOpen($event)">
          <span class="icon is-small m-0"><i class="tri-camera has-background-white" aria-hidden="true"></i></span>
        </button>
      }

      @if (onTablete) {
        <rcbt-scanner-wrapper [isOpen]="scanditOpen" (onScan)="onScan($event)" (onClose)="onClose()"></rcbt-scanner-wrapper>
      }

      <button
        class="button is-info p-2"
        data-cy="add-sim-plan"
        type="submit"
        [hidden]="!!simCode || (!simForm.valid && onTablete)"
        [disabled]="!simForm.valid || loading"
        [ngClass]="{ 'action-feedback': loading }"
      >
        OK
      </button>
    </form>
  }

  @if (messageEsimAuto) {
    <div class="is-5 has-text-warning icon-message">
      <span class="icon is-small"><i class="tri-comment-exclamation-circle" aria-hidden="true"></i></span>
      <p>
        <strong>
          Suite à l'incompatibilité de la sim en cours d'utilisation avec le téléphone sélectionné, une eSIM vient d'être ajoutée au panier.<br />
          Cliquer sur 'eSIM' pour la supprimer.
        </strong>
      </p>
    </div>
  }
</div>

@if (this.message) {
  <div class="is-size-6 has-text-warning">
    <p>
      <strong>
        <span class="icon is-small"><i class="tri-comment-exclamation-circle" aria-hidden="true"></i></span>
        {{ this.message }}
      </strong>
    </p>
  </div>
}
@if (choosenSimType === typeSim.qrEsim && !loading && sim && isReplaceSimComponent) {
  <div>
    <div class="row-display">
      <div class="row-input">
        <button data-cy="deleteReplaceSim" class="button is-warning p-2" [disabled]="loading" (click)="removeSim()">
          <span class="icon is-small"><i class="tri-trash has-background-white" aria-hidden="true"></i></span>
        </button>
      </div>
    </div>
    <div class="row-display is-centered">
      <rcbt-price [product]="sim"></rcbt-price>
    </div>
  </div>
}
