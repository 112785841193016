<div class="is-flex is-vertical side-bar-filter" id="sideBarScroll">
  <div class="is-flex is-aligned-center is-spaced-between">
    <span class="icon is-medium"><i class="tri-sliders" aria-hidden="true"></i></span>
    <span class="title is-size-3 m-0">Filtres</span>
    <button data-cy="clear-filters" class="clear-filters button is-primary" (click)="clearAllFilters()">
      <span class="icon is-medium m-0">
        <i class="tri-trash has-background-white" aria-hidden="true"></i>
      </span>
    </button>
  </div>

  <div class="checkbox m-t-0">
    <input type="checkbox" id="inStock" name="checkbox1" [(ngModel)]="filterService.phoneFiltersAndSort.inStock" (change)="emitFiltersAndSort()" />
    <label for="inStock" class="checkbox-label m-0">En stock</label>
  </div>

  <div class="control has-dynamic-placeholder has-icons-right m-b-2">
    <input
      role="textbox"
      autocomplete="off"
      data-cy="filter-by-text"
      type="text"
      class="input"
      placeholder="Modèle, marque..."
      value=""
      [(ngModel)]="filterService.phoneFiltersAndSort.name"
      #filterByText
    />
    <label>Modèle, marque...</label>
    <div>
      <span class="icon is-small icon-right"><i class="tri-search" aria-hidden="true"></i></span>
    </div>
  </div>
  <div class="divider"></div>

  <div class="list-filter">
    @for (filter of filterService.filtersConfig; track filter) {
      <ng-containter>
        <h3 class="title is-size-4">{{ filter.label }}</h3>
        @for (item of filter.values; track item; let i = $index) {
          <ng-containter>
            @if (isItemCodeDisplay(item, filter.code, i)) {
              <div class="checkbox">
                <input
                  type="checkbox"
                  [id]="filter.code + '-' + item.code"
                  [name]="filter.code + '-' + item.code"
                  (change)="updateAndEmitFilters(filter.code, item.code)"
                  [checked]="this.filterService.isFilterOn(filter.code, item.code)"
                />
                <label [for]="filter.code + '-' + item.code" class="checkbox-label m-0">{{ item.label }}</label>
              </div>
            }
          </ng-containter>
        }
        @if (filter.code === 'brand') {
          <div>
            <div class="link" (click)="allBrand = !allBrand">{{ allBrand ? '-' : '+' }} de marque</div>
          </div>
        }
        <div class="divider"></div>
      </ng-containter>
    }
  </div>
</div>
