<div class="m-t-4">
  <label for="contact" class="is-flex is-aligned-center m-b-2">
    <div class="sticker m-r-15 is-small is-info">BON PLAN</div>
    <b>ODR : </b>&nbsp;{{ odr.amount }}€ remboursés.
    <a class="link m-l-0" [href]="odr.file" target="_blank"><b>Télécharger l'ODR</b></a>
  </label>
  <div class="is-flex is-justified-between is-aligned-center">
    <input
      [formControl]="contact"
      id="contact"
      class="input input-height m-b-0"
      autocomplete="off"
      type="text"
      placeholder="email@email.fr ou N° de portable"
      aria-label="contact input"
    />
    <button class="button button-height is-primary m-1" (click)="onSubmit()" [disabled]="contact.invalid">Envoyer</button>
  </div>
</div>
