<div class="control">
  <div class="field autocomplete-input">
    <div class="control has-dynamic-placeholder has-icons-right">
      <input
        id="input-{{idName}}"
        role="textbox"
        type="text"
        class="input is-default"
        autocomplete="off"
        placeholder="{{label}}"
        [attr.data-cy]="'input-select-'+idName"
        [formControl]="inputControl"
        (focus)="showOptions = true"
        (blur)="onInputBlur()"
        (input)="onInputChange($event.target.value)"
        (keydown.ArrowDown)="onArrowDown()"
        (keydown.ArrowUp)="onArrowUp()"
        (keydown.Enter)="onEnter()"
        [attr.aria-expanded]="showOptions"
        aria-autocomplete="list"
        [ngClass]="{'is-success' : inputControl.valid,
        'is-danger' : inputControl.errors, 'disabled' : inputControl.disabled}"

        />
      <label for="input-{{label}}" rcbtAsterisk [formcontrol]="inputControl">{{label}}</label>

      <div>
        <span class="icon is-small">
          @if (inputControl.valid) {
            <i class="tri-check-circle has-background-success-60"></i>
          }
          @if (inputControl.errors) {
            <i class="tri-exclamation-circle has-background-error-60"></i>
          }
        </span>
      </div>
    </div>
    @if (showOptions) {
      <div class="autocomplete-options" id="autocomplete-options">
        @for (option of filteredOptions; track option; let i = $index) {
          <div
            class="option p-2"
            [class.active]="i === activeOptionIndex"
            (click)="selectOption(option)"
            (mouseenter)="setActiveOptionIndex(i)"
            role="option"
            [id]="getActiveDescendantId(i)"
            >
            {{ option.code }} - {{ option.name }}
          </div>
        }
      </div>
    }
  </div>
</div>
