import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { TimeoutService } from '@base/services/timeout.service';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ResolveEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { LocationService } from '@base/services/location.service';
import { InterceptorsDirective } from '@base/directive/interceptors.directive';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TagService } from '@base/services/tag.service';
import { Subscription } from 'rxjs';
import { ScanditService } from '@services/scandit.service';
import { CartService } from '@services/cart.service';
import { InsurancePartner } from '@model/catalog/products/subscription/insurance-partner';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { concatMap, mergeMap } from 'rxjs/operators';
import { IntraCommunicationService } from '@services/intra-communication.service';
import { ModalMedi7WarningComponent } from '@components/modal/modal-medi7-warning/modal-medi7-warning.component';
import { FundingService } from '../../fundings/services/funding.service';
import { PrismeLoggerService } from '../../shared/prisme/prisme-logger.service';
import { PrismeLogType } from '../../shared/prisme/prisme-log-type.enum';
import { Customer } from '@checkout/cart/customer/customer';
import { User } from '../../user/user';
import { BrowseConfigService } from '../../context/browse-config.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { UserService } from '@services/user.service';
import { TopBarComponent } from '@components/top-bar/top-bar.component';
import { DialogService } from '@ngneat/dialog';
import { ReprisePanierComponent } from '@components/modal/reprise-panier/reprise-panier.component';

/**
 * This class represents the main application component.
 */
@Component({
  selector: 'rcbt-app',
  templateUrl: 'container.component.html',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TopBarComponent],
})
export class ContainerComponent implements OnDestroy, OnInit {
  public loading = false;
  public customer: Customer;
  public user: User;
  public onSummaryPage = false;
  public onAccessoryPricePage = false;
  public onEligPage = false;
  public onCustomWelcomePage = false;
  public onCustomRecapPage = false;
  public scanFormIsOpen = false;

  private repriseModalOpened = false;
  private timeoutSubscription: Subscription = new Subscription();
  private readonly idLoader = 'containerLoader';

  constructor(
    private timeoutService: TimeoutService,
    private router: Router,
    private zone: NgZone,
    private readonly tagService: TagService,
    private readonly locationService: LocationService,
    private scanditService: ScanditService,
    private cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private intraCommunicationService: IntraCommunicationService,
    @Inject(DOCUMENT) private document: Document,
    protected prismeLogger: PrismeLoggerService,
    private fundingService: FundingService,
    private browseConfigService: BrowseConfigService,
    private customerService: CustomerService,
    private userService: UserService,
    private dialog: DialogService,
  ) {}

  @HostListener('window:popstate', ['$event'])
  public onPreventBackButton(ev: PopStateEvent): void {
    if (!this.cartService.cart?.cartId) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    setTimeout(() => {
      window.history.pushState(null, '', '403');
    }, 1000);
    this.timeoutService.timeout.next(true);
  }

  public ngOnInit(): void {
    this.customer = this.customerService.customer;
    this.user = this.userService.user;
    if (['IHMSD', 'IHMPRIXACCESSOIRE'].includes(this.browseConfigService.browseConfig.browseActionType)) {
      this.onAccessoryPricePage = true;
    }
    if (this.browseConfigService.browseConfig.browseActionType === 'ELIG') {
      this.onEligPage = true;
    }

    this.onCustomWelcomePage = this.router.url.startsWith('/accap/welcome');
    this.onCustomRecapPage = this.router.url.startsWith('/accap/recapitulatif');

    this.manageMedi7Reprise();
    this.timeoutSubscription.add(
      this.router.events.subscribe(evt => {
        if (!(evt instanceof NavigationStart)) {
          if (this.locationService.params?.debug === '1') {
            new InterceptorsDirective(this.document).loadFiles();
          }
          return;
        }
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      }),
    );
    this.timeoutSubscription.add(
      this.timeoutService.timeout.subscribe((data: boolean) => {
        if (data && !this.repriseModalOpened) {
          this.zone.run(() => {
            this.repriseModalOpened = true;
            this.openRepriseModal();
          });
        }
      }),
    );
    this.tagService.addContentSquareScript();
    this.tagService.pushVirtualPage();
    this.timeoutSubscription.add(
      this.router.events.subscribe((event: NavigationEnd | NavigationCancel | NavigationError) => {
        this.navigationInterceptor(event);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.timeoutSubscription.unsubscribe();
    this.globalLoaderService.deleteStatusByActionTypes(this.idLoader);
  }

  public navigationInterceptor(event: NavigationStart | NavigationEnd | NavigationCancel | NavigationError): void {
    if (event instanceof ResolveEnd || event instanceof NavigationEnd) {
      this.onSummaryPage = this.router.url.startsWith('/panier/recapitulatif');
      if (this.onSummaryPage) {
        this.tagService.sentCustomVarsForSummary();
      }
      this.tagService.pushVirtualPage();
    }
  }

  private openRepriseModal(): void {
    const dialogRef = this.dialog.open(ReprisePanierComponent, {
      minWidth: '80%',
      maxWidth: '80%',
      closeButton: false,
    });

    dialogRef.afterClosed$.subscribe(result => {
      this.repriseModalOpened = false;
    });
  }

  private sendDataToPrisme(): void {
    const msg = 'popin resynchro';
    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, { message: msg });
  }

  private manageMedi7Reprise(): void {
    const isOnTabletOrTpvActive = this.scanditService.isOnTabletOrTpvActive();
    const schemeMedi7 = this.cartService.cart.schemes.find(
      s => !!s.getProductByType<InsurancePartner>(InsurancePartner),
    );
    if (!isOnTabletOrTpvActive && !!schemeMedi7 && !this.cartService.cart.contractSigned) {
      const dialogRef = this.dialog.open(ModalMedi7WarningComponent, {
        minWidth: '80%',
        maxWidth: '80%',
        data: {
          switchToTPV: true,
        },
      });

      dialogRef.afterClosed$.subscribe(result => {
        if (result) {
          const insuranceProduct: InsurancePartner = schemeMedi7.getProductByType<InsurancePartner>(InsurancePartner);
          this.globalLoaderService
            .showLoaderUntilFinalize(
              this.cartService.remove(insuranceProduct.uniqueId, schemeMedi7.uniqueId).pipe(
                mergeMap(() => {
                  this.cartService.removeCreditFundingMode();
                  return this.fundingService.postFundingMode(this.cartService);
                }),
                concatMap(() => this.cartService.refreshCart()),
              ),
              this.idLoader,
            )
            .subscribe(() => {
              this.intraCommunicationService.refreshMedi7Components();
            });
        }
      });
    }
  }
}
