import { Component } from '@angular/core';
import { NgClass } from '@angular/common';
import { AccessoriesComponent } from '../accessories/accessories.component';
import { InsuranceListComponent } from '../../../partner/insuranceList/insurance-list.component';
import { ServicesComponent } from '@components/category/services/services.component';
@Component({
  selector: 'rcbt-app-cross-sell',
  templateUrl: './cross-sell.component.html',
  standalone: true,
  styleUrls: ['./cross-sell.component.scss'],
  imports: [NgClass, ServicesComponent, AccessoriesComponent, InsuranceListComponent],
})
export class CrossSellComponent {
  public activeAccordions: { [key: string]: boolean } = { piecesJustif: true };

  public ngOnInit(): void {
    this.toggleAccordion('accessoires');
  }

  public toggleAccordion(id: string): void {
    if (!this.activeAccordions[id]) {
      this.activeAccordions = {};
      this.activeAccordions = { [id]: true };
    } else {
      this.activeAccordions[id] = !this.activeAccordions[id];
    }
  }
}
