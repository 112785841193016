<!-- it has this [ngClass]="{ hasSimReplace: !isRenew && hasSim }" -->
<div class="is-flex is-spaced-around is-fullwidth has-background-white category-nav">
  @if (!isRenew) {
    <div class="list-item">
      <a id="linnk_plans" class="item" [routerLink]="['/category', 'plan']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <span class="item-text">Forfaits</span>
      </a>
    </div>
  }

  <div class="list-item">
    <a id="link_telephones" class="item" [routerLink]="telephoneRoute" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
      <span class="item-text">
        Téléphones
        @if (!isRenew) {
          <span> & Hotspots</span>
        }
      </span>
    </a>
  </div>

  @if (!isRenew) {
    <div class="list-item">
      <a id="link_cross_sell" class="item" [routerLink]="['/category', 'ventes-complementaires']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <span class="item-text">Ventes complémentaires</span>
      </a>
    </div>
  }

  @if (canShowReplaceSim) {
    <div class="list-item">
      <a id="link_replaceSim" class="item" [routerLink]="['/category', 'replace-sim']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <span class="item-text">Replace Sim</span>
      </a>
    </div>
  }
</div>
