<div class=" modal-content">
  <div class="close-modal">
    <span class="icon is-medium" aria-label="Icône de taille moyenne" (click)="close()">
      <i class="tri-times-circle" aria-hidden="true"></i>
    </span>
  </div>

  <div class="title">Reprise mobile</div>

  <div class="data-mobile-takeback">
    <div class="part-left">

      <div class="full-width" [formGroup]="imeiForm">
        <div class="data-row">
          <label>Entrer le numéro IMEI ou un bon de cession</label>
          <div class="full-width input-button field">
            <div class="full-width control has-dynamic-placeholder">
              <input role="textbox" type="tel"
                class="input is-default"
                placeholder="IMEI ou bon de cession"
                (input)="search($event)" maxlength="17" formControlName="imei"
                data-cy="mobileTakeBackImei">
              <label>IMEI ou bon de cession</label>
            </div>
            @if (onTablet) {
              <button class="button is-info p-2" data-cy="scan-reprise" (click)="scanImei()">
                <span class="icon is-small m-0"><i class="tri-camera has-background-white" aria-hidden='true'></i></span>
              </button>
            }
            @if (onTablet) {
              <rcbt-scanner-wrapper [isOpen]="scanditOpen" (onScan)="onScan($event)" (onClose)="onClose()"></rcbt-scanner-wrapper>
            }
          </div>
          @if (!isValidImei()) {
            <label class="has-text-danger">
              Le format est incorrect ! Veuillez saisir un IMEI ou un bon de cession valide.
            </label>
          }
        </div>

        @if (productlist?.length) {
          <div class="data-row">
            <label>Veuillez sélectionner votre modèle de téléphone:</label>
            <div class="control has-dynamic-placeholder">
              <select id="select-terminal" formControlName="phoneModel" (change)="getFormQuestion()" class="has-text-tertiary" data-cy="mobileTakeBackSelect">1
                <option [value]="null" [disabled]="isLoading">Sélectionner votre terminal</option>
                @for (product of productlist; track product) {
                  <option [ngValue]="product"
                    [disabled]="isLoading">{{product.nom}}
                  </option>
                }
              </select>
              <label for="select-terminal">Sélectionner votre terminal</label>
            </div>
          </div>
        }
      </div>

      <div class="data-row">
        @if (success) {
          <label class="has-text-sucess">
            Veuillez vérifier qu'il s'agit bien du même mobile, du même IMEI et que l'état correspond à la déclaration initiale.
            Nous vous rappelons que l'identité du bon de cession doit correspondre à celle de l'acte de vente.
          </label>
        }
      </div>

      @if (questionsList && questionsList.length > 0) {
        <div class="data-row" [formGroup]="questionForm"  data-cy="mobileTakeBackList">
          @for (question of questionsList; track question; let i = $index) {
            <div class="qa">
              <div class="question">
                <ng-template #popContent>
                  @for (response of question.reponses; track response) {
                    <div class="popover-response">
                      <div class="popover-label">
                        <b>{{response.libelle}}</b>
                      </div>
                      <div class="popover-details">
                        <ul >
                          @for (d of response.description; track d) {
                            <li>● <span [innerHTML]="d"></span></li>
                          }
                        </ul>
                      </div>
                    </div>
                  }
                </ng-template>
                <!-- TOFIX: redo bellow without bootstrap -->
                <label>{{question.libelle}}
                  <!-- <span class="icon is-small" #p2="ngbPopover" [ngbPopover]="popContent" [placement]="i === 0 ? 'right' : 'top'" container="body" (click)="togglePopover(p2)">
                    <i class="tri-infos-circle" aria-hidden='true'></i>
                  </span> -->
                </label>
                <input type="text" autocomplete="off" formControlName="{{question.id}}" [value]="question.id" [hidden]='true'>
              </div>
              @if (question.qrCode) {
                <div class="qrCode m-l-4 m-b-4">
                  <div class="qr-code-bloc is-flex is-aligned-center">
                    <img src="assets/images/QR_Code_WebApp_test_DeadPixel.png" class="qrCodeDeadPixel" alt="qrcode"/>
                    <span class="has-text-primary m-l-5">Testez l'écran<br>en ligne</span>
                  </div>
                </div>
              }
              <div class="answer options">
                @for (response of question.reponses; track response) {
                  <div class="field">
                    <div class="control">
                      <input type="radio" id="{{question.id}}-{{response.id}}" value="{{response.id}}" [checked]="questionForm.get('response-'+question.id).value === response.id" formControlName="response-{{question.id}}">
                      <label for="{{question.id}}-{{response.id}}" data-cy="btn-response">{{response.libelle}}</label>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          @if (geolocalisationError) {
            <div class="data-row">
              <p class="text has-text-danger">
                {{geolocalisationError}}
              </p>
            </div>
          }
        </div>
      }

      @if (inventoryWarning) {
        <div class="data-row">
          <p class="text has-text-warning">
            {{inventoryWarning}}
          </p>
        </div>
      }
      @if (inventoryError) {
        <div class="data-row">
          <p class="text has-text-danger">
            {{inventoryError}}
          </p>
        </div>
      }
    </div>

    <div class="part-right">
      <div class="phone-name">
        <p class="title is-level-3 has-text-centered">{{ phone?.marque }}</p>
        <p class="title is-level-3 has-text-centered">{{ phone?.nom }}</p>
      </div>
      <div class="price-infos">
        <h2 class="text margin">Montant de la reprise</h2>
        @if (mobileTakeBackAmount !== undefined) {
          <rcbt-price class="margin" [price]="mobileTakeBackAmount"></rcbt-price>
        }
        <button class="button is-primary margin" (click)="onValid()"
          [disabled]="!isValid() || isLoading || !mobileTakeBackAmount"
        [ngClass]="{'action-feedback': isLoading}" data-cy="mobileTakeBackOk">Appliquer</button>
        @if (isValid() && !isLoading && mobileTakeBackAmount) {
          <p class="text is-3 has-text-centered margin">
            <span class="icon is-small"><i class="tri-comment-info" aria-hidden='true'></i></span>
            En cas de reprise d'un terminal eSim, pensez à supprimer le profil eSim du terminal
          </p>
        }
      </div>
    </div>
  </div>

  @if (error) {
    <div class="text has-text-danger"> {{error}} </div>
  }
</div>
