<div class=" customer-page">
  <section class="accordions" data-accordion="" data-cy="titulaire-steps">
    @if (isPro) {
      <rcbt-cart-customer-identity-pro data-cy="identityPro-step"
        #stepIdentityPro
      [isActive]="currentStep.value === customerSteps.identity"></rcbt-cart-customer-identity-pro>
    } @else {
      <rcbt-cart-customer-identity data-cy="identity-step"
        #stepIdentity
      [isActive]="currentStep.value === customerSteps.identity"></rcbt-cart-customer-identity>
    }
  <br>
    @if (customerService.nbSteps >= maxSteps.address) {
      <rcbt-cart-customer-address data-cy="address-step"
        #stepAddress
        [isPro]="isPro"
        [isDisabled]="addressDisabled"
      [isActive]="currentStep.value === customerSteps.address"></rcbt-cart-customer-address>
      <br>
    }

    @if (customerService.nbSteps === maxSteps.payment) {
      <rcbt-cart-customer-payment data-cy="payment-step"
        #stepPayment
        [isDisabled]="paymentDisabled"
      [isActive]="currentStep.value === customerSteps.payment"></rcbt-cart-customer-payment>
    }
  </section>
</div>
