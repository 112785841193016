<div class=" close-qview">
  <span class="icon is-large" btl-tag="QV_ALL_PLAN_CLOSE" btl-tag-params="[child]" (click)="closeClicked()">
    <i class="icon tri-times" aria-hidden="true"></i>
  </span>
</div>

@if (enlargedImage && currentEquipment.medias[0]) {
  <div class=" image-zoom" (click)="enlargedImage = false">
    <div class="background-zoom is-flex is-justified-center is-aligned-center">
      <img class="enlarged-image" [src]="currentEquipment?.image | media" alt="{{ currentEquipment?.nom }}" />
      <span class="icon is-large">
        <i class="tri-zoom-out" aria-hidden="true"></i>
      </span>
    </div>
  </div>
}
<div>
  <div class="is-fullwidth is-fullheight p-10 rcbtQview">
    @if (!loadingCtxCatalogError) {
      <div class="is-fullwidth is-fullheight is-flex">
        @if (currentEquipment) {
          <section class="device-section" [ngClass]="{ isReco: currentEquipment.recommande }">
            @if (currentEquipment.recommande) {
              <div class="isRecoTitle">Sélectionné pour votre client</div>
            }
            <div class="row">
              <div class="columns">
                <div class="column is-5 device-visual">
                  @if (currentEquipment?.medias) {
                    <div>
                      @if (!onComparator) {
                        <div>
                          @if (!!currentEquipment.etiquetteAnimCo || odr) {
                            <span class="tags is-centered">
                              @if (!!currentEquipment.etiquetteAnimCo) {
                                <span class="tag has-text-weight-semibold m-b-5" data-cy="tag-mobileTakeBackBonus">{{ currentEquipment.etiquetteAnimCo }}</span>
                              }
                              @if (odr) {
                                <span class="tag has-text-weight-semibold has-background-info has-text-white m-b-5" data-cy="tag-odr">ODR -{{ odr.amount }}€</span>
                              }
                            </span>
                          }
                        </div>
                      }
                      <div class="selected-image-frame m-r-2" (click)="enlargedImage = true">
                        @if (currentEquipment.medias[0]) {
                          <img class="selected-image" [src]="currentEquipment?.image | media" alt="{{ currentEquipment?.nom }}" />
                        }
                        <span class="icon is-medium">
                          <i class="tri-zoom-in" aria-hidden="true"></i>
                        </span>
                      </div>
                      <ul class="phone-thumbnail">
                        @for (image of currentEquipment.medias; track image) {
                          <li class="thumb" (click)="updateMainImage(image)">
                            <img src="{{ image | media }}" class="thumb-image" alt="" />
                          </li>
                        }
                      </ul>
                    </div>
                  }
                </div>
                <div class="column is-7 device-data p-t-3">
                  <div class="name-brand">
                    <h3 class="title is-size-3">
                      <span class="title is-size-5">{{ currentEquipment?.marque }}</span
                      ><br />
                      {{ currentEquipment?.nom }}
                    </h3>
                  </div>
                  <div class="divider m-0 m-t-2"></div>
                  <div class="is-flex is-vcentered p-y-1">
                    <span class="icon is-small is-success">
                      <i class="tri-check-circle" aria-hidden="true"></i>
                    </span>
                    <span class="is-level-1 has-text-weight-semibold">Disponibilité : {{ currentEquipment.quantite }}</span>
                  </div>
                  <div class="divider m-0"></div>
                  <div class="p-0">
                    @if (configuration.colors.length) {
                      <div class="m-b-4">
                        <span class="text is-level-2 has-text-weight-semibold">Couleur</span>
                        <div class="columns is-multiline is-variable is-1 product-colors">
                          @for (color of this.configuration.colors; track color) {
                            <div
                              class="item-color highlighted"
                              (click)="updateConfiguration('color', color)"
                              [ngStyle]="{ 'background-color': color }"
                              [ngClass]="{ selected: selectedConfiguration.color === color }"
                            ></div>
                          }
                        </div>
                      </div>
                    }
                    @if (configuration.capacities.length) {
                      <div >
                        <span class="text is-level-2 has-text-weight-semibold">Mémoire</span>
                        <div class="radio-list">
                          @for (capacity of configuration.capacities; track capacity) {
                            <div class="radio-tile is-horizontal">
                              <input
                                type="radio"
                                name="capacity"
                                [(ngModel)]="selectedConfiguration.capacity"
                                value="{{ capacity }}"
                                id="{{ capacity.split(' ').join('') }}"
                                (ngModelChange)="updateConfiguration('capacity', capacity)"
                              />
                              <label class="radio-label has-text-weight-semibold is-nowrap" for="{{ capacity.split(' ').join('') }}">
                                {{ capacity }}
                              </label>
                            </div>
                          }
                        </div>
                      </div>
                    }
                    <div class="divider m-0"></div>
                    @if (!onComparator) {
                      <div>
                        <ul class="p-y-2">
                          @if (currentEquipment?.resolutionPhoto) {
                            <li class="is-flex is-vcentered">
                              <span class="icon is-small">
                                <i class="tri-camera" aria-hidden="true"></i>
                              </span>
                              <span>
                                Photo &nbsp;&nbsp;&nbsp;&nbsp; <span class="has-text-weight-semibold">{{ currentEquipment.resolutionPhoto }} Mpx</span></span
                              >
                            </li>
                          }
                          @if (currentEquipment?.tailleEcran) {
                            <li class="is-flex is-vcentered">
                              <span class="icon is-small">
                                <i class="tri-mobile" aria-hidden="true"></i>
                              </span>
                              <span>
                                Écran &nbsp;&nbsp;&nbsp;&nbsp; <span class="has-text-weight-semibold">{{ currentEquipment?.tailleEcran }} pouces</span></span
                              >
                            </li>
                          }
                          @if (currentEquipment?.autonomie) {
                            <li class="is-flex is-vcentered">
                              <span class="icon is-small">
                                <i class="tri-battery" aria-hidden="true"></i>
                              </span>
                              <span
                                >Autonomie &nbsp;&nbsp;&nbsp;&nbsp; <span class="has-text-weight-semibold">{{ currentEquipment.autonomie }} h</span></span
                              >
                            </li>
                          }
                          @if (['esim', 'hybride'].includes(currentEquipment?.typeSim)) {
                            <li class="is-flex is-vcentered">
                              <span class="icon is-small">
                                <i class="tri-check-circle" aria-hidden="true"></i>
                              </span>
                              <span>Compatible eSIM</span>
                            </li>
                          }
                          @if (['esim', 'hybride'].includes(currentEquipment?.typeSim) && currentEquipment?.esimTa) {
                            <li class="is-flex is-vcentered">
                              <span class="icon is-small">
                                <img alt="esim TA" src="/assets/images/esimTa.svg" class="esim-ta-icon" />
                              </span>
                              <span>Compatible activation à l’allumage</span>
                            </li>
                          }
                          <li class="is-flex is-vcentered">
                            <span class="icon is-small">
                              <i class="tri-wifi" aria-hidden="true"></i>
                            </span>
                            <span class="d-block">
                              DAS Tête &nbsp;&nbsp;&nbsp;&nbsp;
                              <span class="has-text-weight-semibold">{{ currentEquipment?.dasTete ? currentEquipment.dasTete + 'W/Kg' : 'N.C' }}</span></span
                            >
                          </li>
                          <li class="is-flex is-vcentered">
                            <span class="icon is-small">
                              <i class="tri-wifi" aria-hidden="true"></i>
                            </span>
                            <span>
                              DAS Tronc &nbsp;&nbsp;&nbsp;&nbsp;
                              <span class="has-text-weight-semibold">{{ currentEquipment?.dasTronc ? currentEquipment.dasTronc + 'W/Kg' : 'N.C' }}</span></span
                            >
                          </li>
                          <li class="is-flex is-vcentered">
                            <span class="icon is-small">
                              <i class="tri-wifi" aria-hidden="true"></i>
                            </span>
                            <span>
                              DAS Membre &nbsp;&nbsp;&nbsp;&nbsp;
                              <span class="has-text-weight-semibold">{{ currentEquipment?.dasMembre ? currentEquipment.dasMembre + 'W/Kg' : 'N.C' }}</span>
                            </span>
                          </li>
                          <li class="is-flex is-vcentered">
                            <span class="icon is-small">
                              <i class="tri-mobile-tools" aria-hidden="true"></i>
                            </span>
                            <span
                              >Indice de réparabilité &nbsp;&nbsp;&nbsp;&nbsp;
                              <span class="has-text-weight-semibold">{{ currentEquipment?.indiceReparabilite ? currentEquipment.indiceReparabilite + '/10' : 'N.C' }}</span></span
                            >
                          </li>
                        </ul>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div >
              @if (odr?.amount && !onComparator) {
                <rcbt-odr-quick-view [odr]="odr"></rcbt-odr-quick-view>
              }
            </div>
          </section>
        }
        <section class="plan-section">
          @if (isRenew && !onComparator) {
            <rcbt-catalog-category-view-renew
              class="plans"
              (onClose)="closeClicked($event)"
              [equipmentType]="equipmentType"
              [odr]="odr?.amount && !onComparator"
              [scanCode]="scanCode"
              #plansComponent
            >
            </rcbt-catalog-category-view-renew>
          }
          @if (!isRenew && !onComparator) {
            <rcbt-catalog-category-view-acquise
              class="plans"
              (onClose)="closeClicked($event)"
              [equipmentType]="equipmentType"
              [odr]="odr?.amount && !onComparator"
              [scanCode]="scanCode"
              #plansComponent
            >
            </rcbt-catalog-category-view-acquise>
          }
          @if (onComparator && currentEquipment) {
            <div class="comparator-info is-flex is-justified-center">
              <p>Veuillez sélectionner une couleur et une capacité puis cliquez sur OK.</p>
              <button class="button is-info" (click)="selectProductToCompare(currentEquipment)">OK</button>
            </div>
          }
        </section>
      </div>
    }
    @if (loadingCtxCatalogError) {
      <div class="row reload-button is-justified-center">
        <button class="button is-info" (click)="reload()" [disabled]="globalLoaderService.isAppOnLoadingStatus$ | async">
          <span class="icon is-small"><i class="tri-exclamation-redo" aria-hidden="true"></i></span> Recharger
        </button>
      </div>
    }
  </div>
</div>
