import { EventEmitter, Injectable } from '@angular/core';
import { ModalConsentComponent } from '@components/modal/modal-consent/modal-consent.component';
import { CartService } from '@services/cart.service';
import { finalize, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ScanditService } from '@services/scandit.service';
import { AlertService } from '@services/alert.service';
import { FundingService } from '../fundings/services/funding.service';
import { CustomerCategory } from '@checkout/cart/customer/customer.interface';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { UserService } from '@services/user.service';
import { IPostFundingResponse } from '@interfaces/funding.interface';
import { DialogService } from '@ngneat/dialog';

@Injectable({
  providedIn: 'root',
})
export class ConsumerLoanService {
  public eventInitLoanProcessDone: EventEmitter<string> = new EventEmitter<string>();
  public retryprocessLoan: EventEmitter<void> = new EventEmitter<void>();
  public onSaveConsentment = new Subject();

  constructor(
    private readonly cartService: CartService,
    private readonly dialogService: DialogService,
    private readonly scanditService: ScanditService,
    private readonly alertService: AlertService,
    private fundingService: FundingService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  public needConsent(): boolean {
    return !this.cartService.cart.consent?.toWantConsumerLoanQuotes;
  }

  public displayInfoPopup(): void {
    const dialogRef = this.dialogService.open(ModalConsentComponent, { minWidth: '80%', maxWidth: '80%' });

    dialogRef.afterClosed$.subscribe(result => {
      if (result) {
        this.alertService.emitBeenLoading(true);
        this.saveConsent()
          .pipe(
            catchError(() => of(null)),
            finalize(() => {
              this.onSaveConsentment.next(true);
              this.alertService.emitBeenLoading(false);
            }),
          )
          .subscribe();
      } else {
        this.onSaveConsentment.next(false);
      }
    });
  }

  public isEligibleCredit(): boolean {
    return !!this.userService.user.eligibleCredit;
  }

  public canLoanAccess(): boolean {
    const isOnTabletOrTpvActive: boolean = this.scanditService.isOnTabletOrTpvActive();
    if (!isOnTabletOrTpvActive && this.hasGrantedCredit()) {
      // DIGFACT-55626
      return true;
    }
    return (
      isOnTabletOrTpvActive &&
      this.cartService.isLoanEligible() &&
      this.customerService.customer.category === CustomerCategory.gp
    );
  }

  public hasLoanInCart(final?: boolean): boolean {
    if (final) {
      return this.fundingService.hasGrantedCredit();
    } else {
      return !!this.cartService.cart.creditData;
    }
  }

  public hasGrantedCredit(): boolean {
    return this.hasLoanInCart(true);
  }

  public saveConsent(): Observable<boolean> {
    if (!this.cartService.cart.consent) {
      this.cartService.cart.consent = {};
    }
    this.cartService.cart.consent.toWantConsumerLoanQuotes = true;
    return this.cartService.updateCart();
  }

  public emitInitLoanProcessDone(idTransaction: string): void {
    this.eventInitLoanProcessDone.emit(idTransaction);
  }

  public emitRetryLoanProcessDone(): void {
    this.retryprocessLoan.emit();
  }

  public disableLoan(): Observable<IPostFundingResponse> {
    this.cartService.removeCreditFundingMode();
    return this.fundingService.postFundingMode(this.cartService);
  }
}
