import { Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-modal-update-warning',
  templateUrl: './modal-update-warning.component.html',
  standalone: true,
  styleUrls: ['./modal-update-warning.component.scss'],
})
export class ModalUpdateWarningComponent {
  public loading = false;
  constructor(protected ref: DialogRef) {}

  public close(validated: boolean): void {
    this.ref.close(validated);
  }
}
