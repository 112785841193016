import { Component } from '@angular/core';
import { SvgDirective } from '@base/directive/svg.directive';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-modal-delete-warning',
  templateUrl: './modal-delete-warning.component.html',
  styleUrls: ['./modal-delete-warning.component.scss'],
  standalone: true,
  imports: [SvgDirective],
})
export class ModalDeleteWarningComponent {
  public loading = false;
  constructor(protected ref: DialogRef) {}

  public close(): void {
    this.ref.close(false);
  }

  public continue(): void {
    this.ref.close(true);
  }
}
