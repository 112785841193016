<div class="dialog-modal-container ">
    <div class="modal-header is-centered">
        <h3 class="title is-level-3">Attention</h3>
    </div>
    <div class="modal-text is-flex is-centered">
            <p class="text" [innerHTML]="ref.data.message"></p>
    </div>
    <br>
    <div class="modal-actions">
        <button class="button is-danger" (click)="closeModal(false)">Annuler</button>
        <button class="button is-info" (click)="closeModal(true)">Valider</button>
    </div>
</div>
