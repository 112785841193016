import { Component, Input } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-dialog',
  templateUrl: './dialog.component-modal.html',
  standalone: true,
  styleUrls: ['./dialog.component-modal.scss'],
})
export class DialogComponentModal {
  @Input()
  public message: string;
  constructor(protected ref: DialogRef) {}

  public closeModal(valid: boolean): void {
    this.ref.close(valid);
  }
}
