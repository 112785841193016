import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '@services/cart.service';
import {
  CatalogSimType,
  FrontSimType,
  SimCompatible,
  SimsContextualisesParcourResponse,
  SimsContextualisesResponse,
} from '@interfaces/sim.interfaces';
import { Product } from '@model/catalog/products/product';
import { SimService } from '@services/sim.service';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Sim } from '@model/catalog/products/equipement/sim';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { Catalog } from '@model/catalog/products/catalog';
import { Iericsson, SimCbt } from '@model/catalog/products/equipement/sim/sim-cbt';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { CheckoutStepperService } from '@services/checkout-stepper.service';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { ProductsService } from '@services/products.service';
import { InputForScan, ScanditFieldInterface } from '@interfaces/scandit.interface';
import { ScanConfig } from '@components/top-bar/scan/scan-config.class';
import { StockType } from '@interfaces/types';
import { AddResult } from '@interfaces/cart.interfaces';
import { ScanditService } from '@services/scandit.service';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { SimReplace } from '@model/catalog/products/equipement/sim/sim-replace';
import { EsimReplace } from '@model/catalog/products/equipement/sim/esim-replace';
import { ContractInterface } from '../../../../context/contract.interface';
import { ConfigService } from '@services/config.service';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { GetProductsWithFilterQuery } from '@bytel/pt-ihm-api-portailvente-sapi-catalogue/dist/models/GetProductsWithFilterQuery';
import { JsonCatalog } from '@model/catalog/products/interface/context';
import { Prepaid } from '@model/catalog/products/subscription/plan/prepaid';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PriceComponent } from '@base/price/price.component';
import { ScannerWrapperComponent } from '@components/scanner-wrapper/scanner-wrapper.component';

const LOADING_ACTIONS = {
  deleteSim: '[PlanComponent] deleteSim',
  addSim: '[PlanComponent] addSim',
  getSims: '[PlanComponent] getSims',
};

@Component({
  selector: 'rcbt-sim',
  templateUrl: './sim.component.html',
  styleUrls: ['./sim.component.scss'],
  standalone: true,
  imports: [FormsModule, NgClass, PriceComponent, ScannerWrapperComponent],
})
export class SimComponent implements OnInit, OnDestroy {
  @Input() isReplaceSimComponent: boolean;
  public isRenew: boolean;
  public messageEsimAuto = false;
  public stockData: StockType;
  public loading = false;
  public message: string;
  public loadingItem: boolean;
  public simCode = '';
  public choosenSimType: FrontSimType = FrontSimType.simPhysique;
  public isQrEsimCompatible: boolean;
  public isSimCompatible = true;
  public isEquipmentCompatible = true;
  public onTablete = false;
  public scanCode: string;
  public simDisplay: boolean;
  public removePlan$: EventEmitter<void> = new EventEmitter();
  public isOldSimCompatible = true;
  public readonly typeSim = FrontSimType;
  public sim: Product;
  public scanditOpen = false;
  public tpvActive: boolean;
  public isPaperMode: boolean;

  private qrEsimGencode: string;
  private currProduct: Product;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private simService: SimService,
    private cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private stepperService: CheckoutStepperService,
    private oauth2RessourceService: Oauth2RessourceService,
    private productsService: ProductsService,
    private scanditService: ScanditService,
    private browseConfigService: BrowseConfigService,
    private configService: ConfigService,
    private customerService: CustomerService,
    private checkoutStepperService: CheckoutStepperService,
  ) {}

  public ngOnInit(): void {
    this.sim = this.cartService.getCurrentScheme().getProductByType(Sim);
    this.onTablete = this.scanditService.isOnTablet() || this.browseConfigService.browseConfig.debug;
    this.isRenew = this.cartService.getCurrentScheme().isRenew();
    this.tpvActive = this.configService.data.demat?.tpv?.active;
    this.isPaperMode = this.configService.data.demat?.paper_mode;
    this.subscriptions.add(
      this.simService.loadContextualizedSim$.subscribe(() => {
        this.loadContextualizedSim();
      }),
    );
    this.loadContextualizedSim();
    this.cartServiceSubscribe();
    this.setSimCode();
    this.getScanListener();
    this.subscriptions.add(
      this.cartService.afterRefresh.subscribe(() => {
        this.sim = this.cartService.getCurrentScheme().getProductByType(Sim);
        if (!this.sim) {
          this.messageEsimAuto = false;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setSimCode(): void {
    const sim: Product = this.cartService.getCurrentScheme()?.getProductByType(Sim);
    if (sim) {
      this.simCode = sim.getScanCode();
    }
  }
  /**
   * Si la sim est type sim prépayé (SimCbt) on supprime le forfait
   * car le forfait est déterminé à partir de la sim (ericsson)
   */
  public removeSim(): void {
    this.message = null;
    const sim = this.cartService.getCurrentScheme().products.find(carteSim => carteSim instanceof Sim);
    if (!sim) {
      if (this.isReplaceSimComponent) {
        this.choosenSimType = null;
        this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty());
      }
      this.simCode = null;
    } else {
      if (sim instanceof SimCbt) {
        this.removePlan$.emit();
        return;
      }
      this.globalLoaderService
        .showLoaderUntilFinalize(
          this.cartService.remove(sim?.uniqueId, this.cartService.getCurrentScheme().uniqueId).pipe(
            mergeMap(() => this.cartService.refreshCart()),
            tap(() => {
              if (this.isReplaceSimComponent) {
                this.choosenSimType = FrontSimType.simPhysique;
                this.checkoutStepperService.redirectIfEmptyScheme(this.cartService.getCurrentScheme().isEmpty());
              }
              this.scanCode = '';
              this.processSimCode();
              this.stepperService.changesCurrentStep.next(null);
            }),
            finalize(() => (this.loading = false)),
          ),
          LOADING_ACTIONS.deleteSim,
        )
        .subscribe();
    }
  }

  public prepareAddSim(product: Product, code: string): Observable<Iericsson> {
    if (product instanceof SimCbt) {
      return this.oauth2RessourceService
        .setLocalService()
        .useSalesApi()
        .ventes()
        .ericsson(Sim.prefix + code)
        .get()
        .pipe(
          tap(
            (res: Iericsson) => {
              product.ericssonData = res;
              if (!res.contractId) {
                throw new Error('contractId manquant dans la réponse Ericsson');
              }
              if (!res.offer) {
                throw new Error('Offre manquante dans la réponse Ericsson');
              }
            },
            () => {
              throw new Error('Carte SIM inconnue chez Ericsson');
            },
          ),
          catchError(err => observableThrowError(err)),
        );
    }
    return of({});
  }

  public processSimCode(): void {
    const sim = this.cartService.getCurrentScheme().products.find(o => o instanceof Sim);
    if (sim instanceof Sim) {
      this.simCode = sim.getScanCode();
    } else {
      this.simCode = '';
    }
  }

  /**
   * En renouv si la sim détenue par le client est incompatible avec le telephone dans le panier et que la sim préféré est la qr_esim
   * on ajoute par défaut qr_esim et on met à true ce flag messageEsimAuto
   * => si ce flag vaut true, le clique sur esim (même si esim est déjà coché) doit supprimer la esim en basculant sur FrontSimType.simPhysique
   * @param targetType
   */
  public changeSimChoice(targetType: FrontSimType): void {
    if (this.messageEsimAuto) {
      targetType = FrontSimType.simPhysique;
    }
    if (this.choosenSimType !== targetType) {
      this.message = null;
      this.scanCode = '';
      this.simCode = '';
      if (targetType === FrontSimType.simPhysique) {
        this.removeSim();
        this.choosenSimType = FrontSimType.simPhysique;
      } else {
        this.choosenSimType = FrontSimType.qrEsim;
        this.addESim(this.qrEsimGencode, FrontSimType.qrEsim);
      }
    }
  }

  private addESim(gencode: string, type: string): void {
    this.loading = true;
    this.currProduct = Catalog.getInstanceByType(type);
    this.currProduct.gencode = gencode;
    this.globalLoaderService
      .showLoaderUntilFinalize(
        of(null).pipe(
          mergeMap(() => this.addSim(this.currProduct, true)),
          finalize(() => (this.loading = false)),
        ),
        LOADING_ACTIONS.addSim,
      )
      .subscribe(
        () => {
          this.processSimCode();
          this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
          this.stepperService.changesCurrentStep.next(null);
        },
        error => (this.message = error && error.message ? error.message : error),
      );
  }

  /**
   * [scanSim description]
   * @return {[type]} [description]
   */
  public scanSim(): void {
    if (!this.scanCode?.length) {
      return;
    }
    this.loading = true;
    this.message = null;
    this.checkSim()
      .pipe(
        mergeMap(() => this.addSim(this.currProduct)),
        finalize(() => (this.loading = false)),
      )
      .subscribe(
        () => {
          this.choosenSimType = FrontSimType.simPhysique;
          this.scanCode = '';
          this.processSimCode();
          this.stepperService.changesCurrentStep.next(null);
        },
        error => (this.message = error && error.message ? error.message : error),
      );
  }

  public addSim(sim: Product, force = false): Observable<boolean> {
    return this.prepareAddSim(sim, this.scanCode).pipe(
      mergeMap(() => {
        if (sim instanceof SimCbt) {
          return this.productsService
            .getJsonProductsByFilter({ listGencode: sim.ericssonData.offer } as GetProductsWithFilterQuery)
            .pipe(
              mergeMap((products: JsonCatalog) => {
                if (!products[sim.ericssonData.offer]) {
                  throw new Error(`L'offre ${sim.ericssonData.offer} n'existe pas dans le catalogue.`);
                }
                const ericssonPlan = Catalog.getInstanceByType(Prepaid.planType);
                ericssonPlan.gencode = sim.ericssonData.offer;
                return this.cartService.add(ericssonPlan, 1, true).pipe(mergeMap(() => this.cartService.add(sim)));
              }),
            );
        }
        return this.cartService.add(sim, 1, force);
      }),
      mergeMap(result => this.cartService.refreshCart().pipe(map(() => result))),
    );
  }

  public scanOpen(event: CustomEvent): void {
    // if (event instanceof MouseEvent) {
    //   event.preventDefault();
    // }
    // this.message = null;
    // if (this.onTablete && event !== false) {
    //   this.scanditService.openScanForField(
    //     this.isReplaceSimComponent ? InputForScan.simReplace : InputForScan.simPlanCheckout,
    //   );
    // }
    this.scanditOpen = true;
  }

  public removeScanCode(): void {
    this.scanCode = '';
  }

  public onScan(event: string): void {
    this.scanCode = event;
    this.scanSim();
    this.onClose();
  }
  public onClose(): void {
    this.scanditOpen = false;
  }

  private checkSim(): Observable<void> {
    return this.cartService.getItemFromStockOrReservedProducts(this.scanCode, ScanConfig.sim['code']).pipe(
      mergeMap((data: StockType[]) => {
        this.stockData = data[0];
        return this.productsService
          .getJsonProductsByFilter({ listGencode: this.stockData.gencode } as GetProductsWithFilterQuery)
          .pipe(
            mergeMap((products: JsonCatalog) => {
              this.currProduct = Catalog.getInstanceByType(products[this.stockData.gencode].type_id);
              this.currProduct.gencode = this.stockData.gencode;
              // todo remplacer de manière global le JsonProduct par le retour de sapi (ProductDto) pour eviter le object.assign
              Object.assign(this.currProduct, { data: { sim_model: products[this.stockData.gencode].sim_model } });
              if (!this.currProduct.setScanCode(this.scanCode)) {
                return observableThrowError('Code barre invalide');
              }
              const resultCheckAdd: AddResult = this.currProduct.canAdd(
                this.cartService.getCurrentScheme().browseType,
                this.cartService.getCurrentScheme().isPrepaid(),
                this.cartService.getCurrentScheme().isFnb(),
              );
              if (!resultCheckAdd.status) {
                return observableThrowError(resultCheckAdd.addCheck.message);
              }
              return this.isReplaceSimComponent ? this.checkSimReplace() : of(null);
            }),
          );
      }),
      catchError(err => observableThrowError(err)),
    );
  }

  private checkSimReplace(): Observable<void> {
    if (!(this.currProduct instanceof SimReplace) && !(this.currProduct instanceof EsimReplace)) {
      throw new Error('Carte Sim non valide');
    }

    let checkEsimObs: Observable<void> = of(null);
    if (this.currProduct instanceof EsimReplace && !this.cartService.getCurrentScheme().isRenew()) {
      const offerId: string = this.getOfferId();
      if (!offerId) {
        return observableThrowError('La vente de eSIM est impossible sur ce type de contrat');
      }
      checkEsimObs = this.oauth2RessourceService
        .referentiel()
        .offres(offerId)
        .get()
        .pipe(
          tap(data2 => {
            if (
              data2.famillesVente.map(f => f.idFamilleVente).indexOf(this.configService.data.salesGroupCode + '') !== -1
            ) {
              return observableThrowError('La vente de eSIM est impossible sur ce type de contrat');
            }
          }),
        );
    }
    return checkEsimObs;
  }

  protected getOfferId(): string {
    const selectedContract = this.customerService.customer.contracts.find(
      (contrat: ContractInterface) => Number(contrat.id) === this.cartService.getCurrentScheme().contractId,
    );
    if (selectedContract && selectedContract.abonnement) {
      const split: string[] = selectedContract.abonnement._links.offre.href.split('/');
      return split[split.length - 1];
    }
    return null;
  }

  private loadContextualizedSim(): void {
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.simService.simsContextualises(this.isReplaceSimComponent).pipe(
          tap((res: SimsContextualisesResponse) => {
            const parcours = res.parcours.find(p => p.estCourant);
            this.updateQrEsimCompatibility(parcours);
            this.updateSimCompatibility(parcours);
            this.updateEquipmentCompatibility(parcours);
            this.selectDefaultSim(parcours);
          }),
        ),
        LOADING_ACTIONS.getSims,
      )
      .subscribe();
  }

  private updateQrEsimCompatibility(parcours: SimsContextualisesParcourResponse): void {
    this.isQrEsimCompatible = this.getIsQrEsimCompatible(parcours);
    if (this.isQrEsimCompatible) {
      this.qrEsimGencode = this.getQrEsimGencode(parcours);
    }
  }

  private updateSimCompatibility(parcours: SimsContextualisesParcourResponse): void {
    this.isSimCompatible = this.getIsSimCompatible(parcours);
    this.isOldSimCompatible = !!parcours.simCompatible;
  }

  private updateEquipmentCompatibility(parcours: SimsContextualisesParcourResponse): void {
    if (!this.sim) {
      return;
    }

    const simCompatible = parcours.simsCompatibles.find(sim => sim.gencode === this.sim.gencode);
    if (!simCompatible || simCompatible.terminalCompatible || simCompatible.terminalCompatible === undefined) {
      this.isEquipmentCompatible = true;
    } else {
      this.isEquipmentCompatible = false;
    }
  }

  private getQrEsimGencode(parcours: SimsContextualisesParcourResponse): string {
    return parcours.simsCompatibles.find(s => [CatalogSimType.qrEsim, CatalogSimType.qrEsimReplace].includes(s.type))
      .gencode;
  }

  private getIsQrEsimCompatible(parcours: SimsContextualisesParcourResponse): boolean {
    return parcours.simsCompatibles.some(s => [CatalogSimType.qrEsim, CatalogSimType.qrEsimReplace].includes(s.type));
  }

  private getIsSimCompatible(parcours: SimsContextualisesParcourResponse): boolean {
    if (this.isReplaceSimComponent) {
      return parcours.simsCompatibles.some(s =>
        [CatalogSimType.simReplace, CatalogSimType.esimReplace].includes(s.type),
      );
    } else if (this.cartService.getCurrentScheme().isPrepaid()) {
      return parcours.simsCompatibles.some(s => CatalogSimType.simCbt === s.type);
    } else {
      return parcours.simsCompatibles.some(s =>
        [CatalogSimType.sim, CatalogSimType.esim, CatalogSimType.simReplace, CatalogSimType.esimReplace].includes(
          s.type,
        ),
      );
    }
  }

  private selectDefaultSim(parcours: SimsContextualisesParcourResponse): void {
    const preferredSim: SimCompatible = parcours.simsCompatibles.find(compatibleSim => compatibleSim.prefere);
    const sim: Sim = this.cartService.getCurrentScheme().getProductByType(Sim);

    if (sim) {
      this.choosenSimType = sim.isQrEsim() ? FrontSimType.qrEsim : FrontSimType.simPhysique;
      return;
    }

    if (this.cartService.getCurrentScheme().isRenew() && this.isOldSimCompatible) {
      return;
    }

    if (!preferredSim) {
      return;
    }

    if ([CatalogSimType.qrEsim, CatalogSimType.qrEsimReplace].includes(preferredSim.type)) {
      this.choosenSimType = FrontSimType.qrEsim;
      this.addESim(preferredSim.gencode, preferredSim.type);
      if (this.isRenew) {
        this.messageEsimAuto = true;
      }
      return;
    }

    this.choosenSimType = FrontSimType.simPhysique;
  }

  private getScanListener(): void {
    if (this.onTablete) {
      this.subscriptions.add(
        this.scanditService.scanForField.subscribe((data: ScanditFieldInterface) => {
          if ([InputForScan.simPlanCheckout, InputForScan.simReplace].includes(data.field)) {
            if (data.scanCode && data.scanCode.length > 0) {
              this.loading = true;
            }
            this.scanCode = data.scanCode;
            this.checkSim()
              .pipe(mergeMap(() => this.addSim(this.currProduct)))
              .subscribe(
                () => {
                  this.loading = false;
                  this.simCode = this.scanCode;
                  this.processSimCode();
                  this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
                  this.stepperService.changesCurrentStep.next(null);
                },
                err => {
                  this.message = err;
                  this.loading = false;
                },
              );
          }
        }),
      );
    }
  }

  private cartServiceSubscribe(): void {
    this.cartService.modifiedOffer.subscribe(() => {
      this.setSimCode();
    });
    this.cartService.onChangesSchemes.subscribe(() => this.setSimCode());
  }
}
