<header>
  <nav class="is-flex is-vcentered has-background-white has-max-height">
    <div class="column is-narrow">
      <img src="/assets/images/logo_venteRCBT.png" class="logo-shop" alt="shop" (click)="openCategories()" data-cy="go-home" />
    </div>
    <div class="column is-narrow verticalDivider"></div>

    @if (customerHeader && (customerHeader.firstname || customerHeader.company.raisonSociale) && !isEligStandalonePage) {
      <div class="column customer-info">
        <span class="icon-and-text">
          <span class="icon is-medium" aria-label="">
            <i class="tri-user" aria-hidden="true"></i>
          </span>
          <span class="text-top-bar is-flex is-vertical">
            @if (!customerHeader.company?.raisonSociale) {
              <span class="is-uppercase has-text-weight-bold"> {{ customerHeader.firstname.substring(0, 1).toUpperCase() }}. {{ customerHeader.lastname }} </span>
            }
            @if (!!customerHeader.company?.raisonSociale) {
              <span class="is-uppercase has-text-weight-bold">
                {{ customerHeader.company.raisonSociale }}
              </span>
            }
            @if (!!customerHeader.company?.raisonSociale && customerHeader.company?.noSiren) {
              <span> Siren : {{ customerHeader.company.noSiren }} </span>
            }
            @if (!contract) {
              <span>{{ customerHeader.phoneNumber | telephone }}</span>
            }
            @if (contract) {
              <span>{{ contract.numeroTel | telephone }}</span>
            }
            @if (!!customerHeader.company?.raisonSociale) {
              <span></span>
            }
          </span>
        </span>
      </div>
    }

    <div class="column">
        @if (!isEligStandalonePage && !isCustomWelcomePage) {
        @if (!scanOpened && scanEnable) {
          <button data-cy="open-scan-code" class="button is-secondary" (click)="changeScanStatus(true)">
            <span class="icon is-medium">
              <i class="tri-camera" aria-hidden="true"></i>
            </span>
            &nbsp;Scanner
          </button>
        }

        @if (scanOpened) {
          <button data-cy="close-scan-code" class="button is-secondary" (click)="changeScanStatus(false)">
            <span class="icon is-medium">
              <i class="tri-times-circle" aria-hidden="true"></i>
            </span>
            &nbsp;Fermer
          </button>
        }
        @if (isOnTablet && rcbtScanOpened) {
          <rcbt-scanner-wrapper [isMultiScan]="true" [isOpen]="rcbtScanOpened" (onScan)="onScan($event)" (onClose)="onClose()"></rcbt-scanner-wrapper>
        }
      }
    </div>
    @if (user) {
      <div class="column">
        <span class="icon-and-text">
          @if (showSimulatorLink) {
            <span class="icon is-large simulator" aria-label="" (click)="navigateToSimulator()">
              <i class="tri-hands-helping" aria-hidden="true"></i>
            </span>
          }
          <span class="icon is-medium" aria-label="">
            <i class="tri-store" aria-hidden="true"></i>
          </span>
          <span class="text-top-bar">
            <span class="is-block is-uppercase has-text-weight-bold">{{ user.nomBoutique }}</span>
            <span class="is-block">{{ user.codeEns }} - {{ user.codePdv }}</span>
          </span>
        </span>
      </div>
    }

    <div class="column">
      @if (!hideAccessoryPriceCancelButton && !isCustomWelcomePage && !onCustomRecapPage) {
        <button
          class="button has-icon"
          data-cy="cancel"
          [ngClass]="{ 'is-danger': cancelType === 'cart', 'is-warning': cancelType !== 'cart' || isEligStandalonePage }"
          [disabled]="loading || isNotificationBoardDisplay"
          (click)="cancelAction()"
        >
          <span class="icon is-medium">
            <i class="tri-times has-background-white" aria-hidden="true"></i>
          </span>
          &nbsp;{{ isEligStandalonePage ? 'Fermer' : 'Annuler' }}
        </button>
      }
    </div>

    @if (isEligStandalonePage && !hideAccessoryPriceCancelButton) {
      <div class="column">
        <button class="button is-info has-icon" data-cy="new-stand-alone" (click)="gotoElig()" [disabled]="loading">
          <span class="icon is-medium"><i class="tri-exclamation-redo" aria-hidden="true"></i></span>
          &nbsp;Faire un nouveau test
        </button>
      </div>
    }

    <div class="column is-flex">
      @if (isNotificationBoardButton && nbNotificationBoardMessages > 0) {
        <ng-containner>
          <span
            class="icon is-medium"
            data-cy="notif-btn"
            [ngClass]="{ 'action-feedback': loading, 'has-status': nbNotificationBoardMessages > 0 }"
            (click)="changeNotificationBoardVisibility()"
          >
            <i class="tri-exclamation-circle" data-cy="notif-icon" [ngClass]="{ 'is-shadow': isNotificationBoardUpdated }"></i>
            @if (nbNotificationBoardMessages > 0) {
              <span class="rcbt-badge" data-cy="notif-badge">{{ nbNotificationBoardMessages }}</span>
            }
          </span>
        </ng-containner>
      }
      @if (isCartdisplay && cartService.cart.schemes.length > 0 && !isCustomWelcomePage) {
        <div class="column is-narrow"></div>
        <span class="icon is-medium" data-cy="cart-link" (click)="openCart()" [ngClass]="{ 'has-status': currentSchemeProductsCount > 0 }">
          <i class="tri-shopping-cart" aria-hidden="true"></i>
          @if (currentSchemeProductsCount > 0) {
            <span class="rcbt-badge" data-cy="cart-pastil">{{ currentSchemeProductsCount }}</span>
          }
        </span>
      }
      @if (isCustomWelcomePage || onCustomRecapPage) {
        <div class="column is-narrow"></div>
        <rcbt-tool-links></rcbt-tool-links>
      }
      @if (shouldShowLogButton) {
        <div class="column is-narrow"></div>
        <span class="icon is-medium" data-cy="btn-log" [ngClass]="{ 'action-feedback': loading }" (click)="openFrontLog()">
          <i class="tri-file-list" aria-hidden="true"></i>
        </span>
        <div class="column is-narrow"></div>
      }
    </div>

    @if (user) {
      <div class="column is-narrow has-background-success has-text-success-invert has-text-centered" [ngClass]="{ 'is-flex customlogin': !isCustomWelcomePage }">
        @if (isCustomWelcomePage || onCustomRecapPage) {
          <span class="icon is-medium" (click)="logout()">
            <i class="tri-power" aria-hidden="true"></i>
          </span>
        }
        @if (user.isCS()) {
          <span class="is-block is-uppercase text-top-bar">{{ user.firstname }} {{ user.lastname.substring(0, 1) }}</span>
        }
      </div>
    }
  </nav>
  @if (scanOpened) {
    <!-- !! in case of problem put back rcbt scan !! -->
    <rcbt-form-scan [scanCode]="scanCode"></rcbt-form-scan>
  }
</header>

<rcbt-alert-cart></rcbt-alert-cart>

<rcbt-notification-board
  [isOpened]="isNotificationBoardDisplay"
  [login]="user?.login"
  (emitActive)="recupNotificationBoardActive($event)"
  (emitOpen)="isNotificationBoardDisplay = true"
  (emitNbMsg)="recupNotificationBoardNbMsg($event)"
  (emitUpdated)="recupNotificationBoardUpdated($event)"
  (emitDoClose)="changeNotificationBoardVisibility()"
></rcbt-notification-board>

@if (cancelConfirmDisplay) {
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h2 class="modal-title">Annuler la vente ?</h2>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-lg btn-warning col-auto action" (click)="cancelCart()" [disabled]="loading" [ngClass]="{ 'action-feedback': loading }">Oui</button>
          <button type="button" class="btn btn-lg btn-info" [disabled]="loading" (click)="cancelConfirmDisplay = !cancelConfirmDisplay">Non, continuer</button>
        </div>
      </div>
    </div>
  </div>
}
