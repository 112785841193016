<div class="dialog-modal-container ">
    <div class="modal-header">
        <div class="head">
            <h2 class="title is-level-1">Annulation du financement du panier à crédit</h2>
        </div>
        <div class="modal-close" (click)="close(false)">
            <span class="icon is-medium">
              <i class="tri-times" aria-hidden="true"></i>
            </span>
        </div>
    </div>
    <div class="modal-text">
        La modification d'un acte au panier entraine l'annulation du financement du panier à crédit.
        Voulez-vous vraiment modifier votre acte et annuler le financement du panier à crédit ?
    </div>
    <div class="modal-actions">
        <button data-cy="consent-close" class="button is-primary" (click)="close(false)">Retour au récapitulatif</button>
        <button data-cy="consent-valid" class="button is-info" (click)="close(true)" [disabled]="loading">Modifier mon acte</button>
    </div>
</div>
