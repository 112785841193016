<rcbt-category-nav-bar></rcbt-category-nav-bar>
@if (category === 'ventes-complementaires') {
  <rcbt-app-cross-sell></rcbt-app-cross-sell>
}

<div class="m-t-6">
  @if (category === 'telephones') {
    <rcbt-category-product-list [category]="category"></rcbt-category-product-list>
  }
  @if (category === 'replace-sim') {
    <rcbt-category-replace-sim [showHeader]="true"></rcbt-category-replace-sim>
  }
</div>
