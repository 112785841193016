import { Injectable, Injector } from '@angular/core';
import { ProductsService } from '@services/products.service';
import { FaiStorageService } from './fai-storage.service';
import {
  AddressFullModel,
  EDuplicateAction,
  EligType,
  FaiEligibilityModel,
  HousingModel,
  IAdresseInstallation,
  IGetCommandsResponse,
  IIdAddress,
} from './fai.interfaces';
import { forkJoin, Observable, of } from 'rxjs';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CartService } from '@services/cart.service';
import { GlobalLoaderService } from '@base/services/global-loader.service';
import { DuplicateCommandComponent } from '../components/modal/duplicate-command/duplicate-command.component';
import { EnumActions, PrismeLoggerService } from '../shared/prisme/prisme-logger.service';
import { IPlanConfiguration } from '@model/catalog/products/subscription/IPlanConfiguration';
import { PrismeLogType } from '../shared/prisme/prisme-log-type.enum';
import { ConfigService } from '@services/config.service';
import { FaiScheme } from '@model/fai-scheme';
import { CustomerService } from '@checkout/cart/customer/customer.service';
import { BasicObject } from '@base/base.interfaces';
import { WallGetResponseDetailProductDto } from '@bytel/pt-ihm-api-portailvente-sapic-catalogue/dist/models/components/schemas/WallGetResponseDetailProductDto';
import { ProductSerialized } from '@model/catalog/products/interface/configurable';
import { GetProductsWithFilterQuery } from '@bytel/pt-ihm-api-portailvente-sapi-catalogue/dist/models/GetProductsWithFilterQuery';
import {
  ConsulterCatalogueContextualiseAvecPanier200Response,
  ConsulterCatalogueContextualiseAvecPanierBodyRequest,
  ConsulterCatalogueContextualiseAvecPanierQuery,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogInputHelperDto } from '../contextualized-catalog/dtos/catalog-input-helper.dto';
import { CustomerCategory } from '@checkout/cart/customer/customer.interface';
import { CatalogCtxService } from '../contextualized-catalog/services/catalog-ctx.service';
import { PriceTypes } from '@model/catalog/products/interface/price-type.enum';
import { JsonCatalog } from '@model/catalog/products/interface/context';
import { format, subMonths } from 'date-fns';
import { DialogService } from '@ngneat/dialog';

@Injectable({
  providedIn: 'root',
})
export class FaiEligService {
  public eligResult: FaiEligibilityModel;
  public eligType: EligType = EligType.address;
  public isStandalone = false;
  public serializedEligData: BasicObject = {};
  public eligAddress: string;
  public eligNd: string;
  public eligPto: string;
  public housingList: HousingModel[] = [];
  private static ctxCatalogChungSize = 20;
  private duplicateCommand = false;
  private idAddressList: IIdAddress[] = [];
  private ctxProductsCache = new Map<string, WallGetResponseDetailProductDto[]>();

  constructor(
    private faiStorageService: FaiStorageService,
    protected oauth2RessourceService: Oauth2RessourceService,
    protected cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private configService: ConfigService,
    private customerService: CustomerService,
    private productsService: ProductsService,
    private injector: Injector,
    private catalogCtxService: CatalogCtxService,
    private dialogService: DialogService,
  ) {}

  public serialize(result?: FaiEligibilityModel): void {
    this.eligResult = result ?? this.eligResult;
    this.serializedEligData = {
      eligType: this.eligType,
      eligResult: this.eligResult,
      isStandalone: this.isStandalone,
      addressElig: this.eligAddress,
      eligNd: this.eligNd,
      eligPto: this.eligPto,
      housingList: this.housingList,
    };
    this.faiStorageService.setItem(this.faiStorageService.key.fai, this.serializedEligData);
  }

  public unserialize(): void {
    this.serializedEligData = this.faiStorageService.getItem(this.faiStorageService.key.fai);
    if (this.serializedEligData) {
      this.eligResult = this.serializedEligData.eligResult;
      this.eligType = this.serializedEligData.eligType;
      this.isStandalone = this.serializedEligData.isStandalone;
      this.eligNd = this.serializedEligData.eligNd;
      this.eligPto = this.serializedEligData.eligPto;
      this.housingList = this.serializedEligData.housingList;
      this.eligAddress = this.serializedEligData.addressElig;
    }
  }

  public addProductToCart(product: WallGetResponseDetailProductDto): Observable<ProductSerialized> {
    const body = {
      gencode: product.gencode,
      ...this.getProductAdditionalConfig(product),
    };
    return this.saveFaiConfiguration().pipe(
      mergeMap(() => this.cartService.addProduct(body, true).pipe(mergeMap(() => this.addRelatedProducts(product)))),
    );
  }

  public reset(resetEligType = true): void {
    this.serializedEligData = {
      eligType: resetEligType ? EligType.address : this.eligType,
      isStandalone: this.isStandalone,
    };
    this.unserialize();
  }

  public isEligible(): boolean {
    return ['ftth', 'box_4g', 'box_5g'].some(tech => this.eligResult[tech]?.status);
  }

  public getFormattedAddress(addressFull: AddressFullModel): string {
    return `${addressFull.streetNumber.number === '_none' ? '' : addressFull.streetNumber.number + ' '}
        ${addressFull.streetNumber.complement} ${addressFull.street.label} ${addressFull.zipCode} ${
          addressFull.city.label
        }`;
  }

  public setEligDataFromEvent(e): void {
    this.eligAddress = this.getFormattedAddress(e.event.currentStep.props.addressFull);
    this.eligNd = e.event.currentStep.props.nd;
    this.eligPto = e.event.currentStep.props.pto;
    this.housingList = e.event.currentStep.props.dslInfo?.housingList;
    this.serialize();
  }

  public getInProgressCommands(): Observable<unknown> {
    this.duplicateCommand = this.configService.data.fai?.doublonCommande !== false;
    if (!this.duplicateCommand || !this.customerService.customer?.personId) {
      this.idAddressList = [];
      return of(null);
    }
    return this.oauth2RessourceService
      .commandesCommerciales(this.customerService.customer.personId)
      .get()
      .pipe(
        tap((getCommandsResponse: IGetCommandsResponse) => {
          const date3MonthsAgo: string = format(subMonths(new Date(), 3), 'yyyy-MM-dd');
          this.idAddressList = [];
          getCommandsResponse.items
            .filter(
              c => !['FINALISE', 'ANNULE'].includes(c.statut) && c.dateEnregistrement.substring(0, 10) > date3MonthsAgo,
            )
            .forEach(i => {
              i.offresAchetees
                ?.filter(o => o.type !== 'ACQUISITION_FIXE_TECHNO_MOBILE')
                .forEach(o => {
                  const el = o.elementsCommandes?.find(e => !!e.adresseInstallation);
                  if (!!el) {
                    this.idAddressList.push({ id: i.id, address: el.adresseInstallation });
                  }
                });
            });
        }),
        catchError(() => of(null)),
      );
  }

  public manageDuplicateCommandModal(addressInput: IAdresseInstallation): Observable<EDuplicateAction> {
    const duplicateCommand: IIdAddress = this.idAddressList.find(e => this.isSameAddress(addressInput, e.address));
    if (!duplicateCommand) {
      return of(EDuplicateAction.nextStep);
    }

    this.globalLoaderService.setForceLoadingStatusOff(true);
    const dialogRef = this.dialogService.open(DuplicateCommandComponent, {
      size: 'xl',
      data: {
        idCommand: duplicateCommand.id,
      },
    });
    // res:
    // () => EDuplicateAction.reset /* on close => change address */,
    // () => EDuplicateAction.cancel /* on cancel => cancel act */,
    dialogRef.afterClosed$.subscribe(res => {
      this.tracesTechniquesDuplicate(duplicateCommand.id, res);
      this.globalLoaderService.setForceLoadingStatusOff(false);
    });
  }

  public loadAllProducts(eligResultOffers: string): Observable<WallGetResponseDetailProductDto[]> {
    let allProducts: WallGetResponseDetailProductDto[] = this.ctxProductsCache.get(eligResultOffers);
    if (allProducts) {
      return of(allProducts);
    }
    allProducts = [];
    return this.loadProductsChunk(eligResultOffers).pipe(
      mergeMap((result: ConsulterCatalogueContextualiseAvecPanier200Response) => {
        const loadRemainingProductsObs$: Observable<ConsulterCatalogueContextualiseAvecPanier200Response>[] = [];
        allProducts = result.produits;
        const nbRequests = Math.ceil(result.nombreTotalProduits / FaiEligService.ctxCatalogChungSize);
        let offset = 0;
        for (let i = 1; i < nbRequests; i++) {
          offset += FaiEligService.ctxCatalogChungSize;
          loadRemainingProductsObs$.push(
            this.loadProductsChunk(eligResultOffers, offset).pipe(
              tap(
                (chunkResult: ConsulterCatalogueContextualiseAvecPanier200Response) =>
                  (allProducts = allProducts.concat(chunkResult.produits)),
              ),
            ),
          );
        }
        return loadRemainingProductsObs$.length
          ? forkJoin(loadRemainingProductsObs$).pipe(map(() => allProducts))
          : of(allProducts);
      }),
      tap(() => this.ctxProductsCache.set(eligResultOffers, allProducts)),
    );
  }

  private loadProductsChunk(
    eligResultOffers: string,
    offset = 0,
  ): Observable<ConsulterCatalogueContextualiseAvecPanier200Response> {
    return this.catalogCtxService.postCatalogCtx(
      this.catalogBodywithCart(),
      this.getQueryParamCtx(eligResultOffers, offset),
    );
  }

  private catalogBodywithCart(): ConsulterCatalogueContextualiseAvecPanierBodyRequest {
    return CatalogInputHelperDto.buildBodyCtxWithCart(
      this.customerService.customer,
      this.cartService.cart,
      null,
      this.cartService.getCurrentScheme()?.uniqueId,
    );
  }

  private getQueryParamCtx(eligResultOffers: string, offset = 0): ConsulterCatalogueContextualiseAvecPanierQuery {
    return {
      modePourFinancement: 'auto',
      limite: FaiEligService.ctxCatalogChungSize,
      decalage: offset,
      gencodes: eligResultOffers,
      idPu: this.customerService.customer?.personId ? String(this.customerService.customer?.personId) : undefined,
      clientCategorie:
        this.customerService.customer.category === CustomerCategory.gp ? CustomerCategory.gp : CustomerCategory.pro,
    };
  }

  private isSameAddress(address1: IAdresseInstallation, address2: IAdresseInstallation): boolean {
    if (!!address1 && !!address2) {
      return (
        address1.codeInsee === address2.codeInsee &&
        address1.codeRivoli === address2.codeRivoli &&
        address1.numero === address2.numero
      );
    }
    return false;
  }

  private tracesTechniquesDuplicate(idCommand: string, choice: EDuplicateAction): void {
    const choiceLibelle =
      choice === EDuplicateAction.cancel
        ? EnumActions.actionDuplicateCommandCancel
        : EnumActions.actionDuplicateCommandChange;

    const prismeLogger = this.injector.get(PrismeLoggerService);
    prismeLogger.sendDataToPrisme(PrismeLogType.customLog, {
      message: 'Etape Eligibilité',
      idPu: this.customerService.customer?.personId,
      initialIdCommand: idCommand,
      action: choiceLibelle,
    });
  }

  private addRelatedProducts(offerProduct: WallGetResponseDetailProductDto): Observable<ProductSerialized> {
    if (!offerProduct.ajoutsAutomatiquesFront?.length) {
      return of(null);
    }
    return this.productsService
      .getJsonProductsByFilter({
        listGencode: offerProduct.ajoutsAutomatiquesFront.join(','),
      } as GetProductsWithFilterQuery)
      .pipe(
        mergeMap((relatedProducts: JsonCatalog) => {
          let addProductsObs: Observable<ProductSerialized> = of(null);
          offerProduct.ajoutsAutomatiquesFront.forEach(relatedProductGencode => {
            // todo quand il mette à jour le catalogue faire products[gencodeAutoAdd].to_scan
            if (!relatedProducts[relatedProductGencode]['to_scan']) {
              addProductsObs = addProductsObs.pipe(
                mergeMap(() =>
                  this.cartService.addProduct(
                    this.getSerializedProduct(relatedProducts, offerProduct, relatedProductGencode),
                    true,
                  ),
                ),
              );
            }
          });
          return addProductsObs;
        }),
      );
  }

  private getSerializedProduct(
    relatedProducts: JsonCatalog,
    offerProduct: WallGetResponseDetailProductDto,
    relatedProductGencode: string,
  ): {} {
    if (relatedProductGencode === 'generic-box-fai') {
      return {
        gencode: relatedProductGencode,
        technology: offerProduct.technologie,
        play: offerProduct.jeu,
      };
    } else if (relatedProducts[relatedProductGencode].type_id === 'fai_service') {
      return {
        gencode: relatedProductGencode,
        priceType: PriceTypes.reported,
      };
    } else {
      return {
        gencode: relatedProductGencode,
      };
    }
  }

  private saveFaiConfiguration(): Observable<void> {
    const scheme = this.cartService.getCurrentScheme() as FaiScheme;
    scheme.faiEligId = this.eligResult.id;
    scheme.installationPostalCode = this.eligResult.address.zipCode;
    return this.cartService.updateScheme(scheme).pipe(map(() => null));
  }

  private getProductAdditionalConfig(product: WallGetResponseDetailProductDto): IPlanConfiguration {
    let planConfigIPlanConfiguration;
    if (product.type === 'faim_unlimited') {
      const faiBoxTechno = this.eligResult.box4g.offers?.includes(product.gencode)
        ? this.eligResult.box4g
        : this.eligResult.box5g;
      planConfigIPlanConfiguration = {
        eligTechTokyo: {
          homeZone: faiBoxTechno.homeZone,
        },
      };
    }
    return planConfigIPlanConfiguration;
  }
}
