import { Component, Input } from '@angular/core';

import { EquipmentItem } from '../product-list/product-list.component';
import { ComparatorService } from '@services/comparator.service';
import { ViewDirective } from '@directive/view.directive';
import { IPostCatalogResponse } from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { CONNECTIVITY_ICON_MAP } from '../../../constants/product-list.constant';
import { BonusRepriseComponent } from '@base/components/bonus-reprise/bonus-reprise.component';
import { MediaPipe } from '@base/pipes/media.pipe';
import { NgClass } from '@angular/common';
import { CustomCurrencyPipe } from '@base/pipes/currency.pipe';
import { ComparatorSelectionModalComponent } from '@components/modal/comparator-selection-modal/comparator-selection-modal.component';
import { DialogService } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  standalone: true,
  imports: [BonusRepriseComponent, MediaPipe, NgClass, CustomCurrencyPipe, ViewDirective],
})
export class ProductItemComponent {
  @Input() public readonly index: number;
  @Input() readonly equipment: EquipmentItem;

  constructor(
    public comparatorService: ComparatorService,
    private dialogService: DialogService,
  ) {}

  public connectivityIconMap = CONNECTIVITY_ICON_MAP;

  public isParentSelected(parentEquipment: EquipmentItem): boolean {
    return !!this.getSelectedChild(parentEquipment);
  }

  // todo déplacer le code qui cherche les déclinaisons de produits ici, ce composant décidera d'afficher ou pas la popin
  public productClick(event: MouseEvent, parentEquipment: EquipmentItem): void {
    if (!this.comparatorService.active || !parentEquipment.enfants?.length) {
      return;
    }
    const selectedChild = this.getSelectedChild(parentEquipment);
    if (selectedChild) {
      this.comparatorService.removeProduct(selectedChild.gencode);
      return;
    }
    event.preventDefault();

    this.dialogService.open(ComparatorSelectionModalComponent, {
      minWidth: '80%',
      maxWidth: '80%',
      closeButton: false,
      data: {
        childrenCodes: parentEquipment.enfants,
        parentCode: parentEquipment.gencode,
        bestChild: parentEquipment.meilleurEnfant,
      },
    });
  }

  private getSelectedChild(parentEquipment: EquipmentItem): IPostCatalogResponse {
    if (!parentEquipment.enfants) {
      return null;
    }
    return this.comparatorService.selectedPhones.find(phone => parentEquipment.enfants.includes(phone.gencode));
  }
}
