import { Component, Input } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'rcbt-funding-error',
  templateUrl: './funding-error.component.html',
  styleUrls: ['./funding-error.component.scss'],
  standalone: true,
})
export class FundingErrorComponent {
  constructor(protected ref: DialogRef) {}

  public retry(): void {
    window.location.reload();
  }

  public switchToCash(): void {
    this.ref.close(true);
  }
  public closeModal(): void {
    this.ref.close(false);
  }
}
