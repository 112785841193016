import { StepSubmitInterface } from '@interfaces/step-submit.interface';
import { StepperService } from '@services/stepper.service';
import { StepSerialized } from '@interfaces/step-serialized.interface';
import { Observable, of as observableOf } from 'rxjs';

export abstract class Step implements StepSubmitInterface {
  public path: string;
  public label: string;
  public labelButton: string;
  public isHidden = false;
  public isActive = false;
  public code: string;
  public stepReturnPrisme: string;
  /**
   * Step is submitted if backend call is made and it responded Ok
   * @type {boolean}
   */
  public isSubmitted = false;
  /**
   * Step is ready if all forms are validated and we can click to "next step" button
   * @type {boolean}
   */
  public isReady = true;

  /**
   * Step is allowed so its visible in checkout tunnel, we can hide step if the connected user doesn't have the right role
   * @type {boolean}
   */
  protected _isAllowed = true;

  get isAllowed(): boolean {
    return this._isAllowed;
  }

  set isAllowed(allowed: boolean) {
    this._isAllowed = allowed;
  }

  public setCurrentStepComponent(stepper: StepperService): void {
    stepper.currentStepComponent = this;
  }

  public valideSubmit(): boolean {
    return true;
  }

  public hasPath(): boolean {
    return this.path !== 'undefined';
  }

  /**
   * @returns {StepSerialized}
   */
  public serialize(): StepSerialized {
    return {
      code: this.code,
      isActive: this.isActive,
      isAllowed: this.isAllowed,
      isHidden: this.isHidden,
      isReady: this.isReady,
      isSubmitted: this.isSubmitted,
      label: this.label,
    };
  }

  public submit(): Observable<boolean> {
    this.isSubmitted = true;
    return observableOf(this.isSubmitted);
  }

  public cancel(): boolean {
    this.isSubmitted = false;
    return this.isSubmitted;
  }
}
