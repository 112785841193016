import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { APP_INITIALIZER_PROVIDER } from '@base/providers/app-initializer';
import { ConfigService } from '@services/config.service';
import { StorageService } from '@base/services/storage.service';
import { ToolsService } from '@base/services/tools.service';
import { AppStorageService } from './app/app-storage.service';
import { ErrorService } from '@services/error.service';
import { LogService } from '@base/services/log.service';
import { TopBarService } from '@services/top-bar.service';
import { TimeoutService } from '@base/services/timeout.service';
import { I18n } from '@services/i18n.service';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import { HttpCustomInterceptor } from '@base/interceptor/httpCustom.interceptor';
import { DebugTabService } from '@components/debug-tab/debug-tab.service';
import { GmapsService } from '@services/gmaps.service';
import { AngularPrismeLogger } from '@bytel/prisme-logger/lib/angular';
import { GLOBAL_ERROR_HANDLER_PROVIDER } from './app/shared/global-error-handle/global-error-handle.provider';
import { PrismeInterceptor } from '@base/interceptor/prisme.interceptor';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app/app-routing.module';
import { AccapAdapterModule } from './app/web-component/accap-adapter/accap-adapter.module';
import { AppComponent } from './app/app.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { CookieModule } from 'ngx-cookie';
import { provideCustomer } from '@checkout/cart/customer/customer.component';

if (environment.production) {
    enableProdMode();
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: PrismeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpCustomInterceptor, multi: true },
];
bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CookieModule.forRoot(), BrowserModule, FormsModule, AppRoutingModule,  AccapAdapterModule),
        {
            provide: APP_BASE_HREF,
            useValue: '',
        },
        ConfigService,
        ...provideCustomer(),
        APP_INITIALIZER_PROVIDER,
        ConfigService,
        StorageService,
        ToolsService,
        AppStorageService,
        ErrorService,
        LogService,
        TopBarService,
        TimeoutService,
        provideEnvironmentNgxMask(),
        I18n,
        DebugTabService,
        GmapsService,
        {
            provide: AngularPrismeLogger,
            useFactory: (config: ConfigService) => new AngularPrismeLogger({
                st: 'portailvente_rcbt',
                environnement: config.getEnv(),
                urlEndpoint: config.getResourceUrl() + '/traces-techniques',
                tailleMaxEnvoiQueueMessages: 5,
            }),
            deps: [ConfigService],
        },
        GLOBAL_ERROR_HANDLER_PROVIDER,
        provideHttpClient(withJsonpSupport(),withInterceptorsFromDi()),
        ...httpInterceptorProviders
    ]
})
    .catch(err => console.error(err));
